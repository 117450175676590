<p-dialog
  header="{{ 'MONLOG.LABEL.ADD_EVENT_REVIEW_ENTRY' | translate }}"
  [(visible)]="this.showEntryDialog"
  showEffect="fade"
  [modal]="true"
  [transitionOptions]="'0ms'"
  (onHide)="cancelEntryForm()"
  [style]="{ width: '1000px' }"
>
  @if(!this.editorWorking()){
  <form [formGroup]="form" class="form">
    <section>
      <div class="form-body">
        <p-editor
          formControlName="note"
          [style]="{ height: '300px' }"
          (onTextChange)="validateForm()"
          [readonly]="this.editorReadOnly()"
        >
        </p-editor>
      </div>

      <div class="label align-center">
        <span class="label" [translate]="'MONLOG.LABEL.WAS_CR_INVOLVED'"></span>
        <span class="align-center">
          <div class="margin-left">
            <p-radioButton
              name="controlRoomInvolved"
              [value]="true"
              label="{{ 'COMMON.LABEL.BUTTONS.YES' | translate }}"
              formControlName="controlRoomInvolved"
              (onClick)="crInvolvedChanged($event)"
            ></p-radioButton>
          </div>
          <div class="margin-left">
            <p-radioButton
              name="controlRoomInvolved"
              [value]="false"
              label="{{ 'COMMON.LABEL.BUTTONS.NO' | translate }}"
              formControlName="controlRoomInvolved"
              (onClick)="crInvolvedChanged($event)"
            ></p-radioButton>
          </div>
        </span>
      </div>

      <div class="modal-buttons">
        <div>
          <button
            pButton
            label="{{ 'COMMON.LABEL.BUTTONS.OK' | translate }}"
            icon="fa fa-fw fa-check"
            (click)="processEntry()"
            [disabled]="!this.form.valid"
          ></button>
        </div>
        <div class="margin-left">
          <button
            pButton
            label="{{ 'COMMON.LABEL.BUTTONS.CANCEL' | translate }}"
            icon="fa fa-fw fa-ban"
            (click)="cancelEntryForm()"
          ></button>
        </div>
      </div>
    </section>
  </form>
  } @else {
  <app-progress-spinner [enabled]="this.editorWorking()"></app-progress-spinner>
  }
</p-dialog>
