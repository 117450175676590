<div class="monlogs-container">
  <p-blockUI [blocked]="loading === true"></p-blockUI>
  <div>
    <div id="contact-action-buttons">
      <div class="table-header">
        <div id="business-units-wrapper">
          <app-business-unit-filter
            (selectedBusinessUnitsChanged)="handleBusinessUnitChange($event)"
            #buFilter
          ></app-business-unit-filter>
        </div>
        <div class="button-wrapper">
          <span class="filter-label"
            >{{ "MONLOG.LABEL.ADDITIONAL_VIEWS" | translate }}:</span
          >
          <span
            class="no-padding-top-bottom"
            style="text-align: left !important"
          >
            <p-multiSelect
              [options]="additionalFilters"
              [maxSelectedLabels]="10"
              [(ngModel)]="selectedFilters"
              (onChange)="handleAdditionalFilter($event)"
              placeholder="Additional Filters"
            ></p-multiSelect>
          </span>
        </div>
        <div class="button-wrapper" *ngIf="actions && actions.length > 0">
          <span
            style="
              font-weight: 700;
              text-align: right;
              padding-right: 0.25em;
              margin-left: 4px;
            "
            >{{ "COMMON.LABEL.ACTIONS" | translate }}:</span
          >
          <span
            class="no-padding-top-bottom"
            style="text-align: left !important"
          >
            <p-dropdown
              [options]="actions"
              (onChange)="actionEvent($event, dd)"
              [(ngModel)]="selectedAction"
              [ngModelOptions]="{ standalone: true }"
              styleClass="action-drop-down"
              placeholder="Actions"
              #dd
            ></p-dropdown>
          </span>
        </div>
        <div class="button-wrapper">
          <button
            pButton
            class="width-eighty"
            label="{{ 'COMMON.LABEL.BUTTONS.CLEAR' | translate }}"
            icon="fa fa-fw fa-eraser"
            (click)="clearScreen()"
          ></button>
          <button
            pButton
            class="refresh-button width-twenty"
            icon="fa fa-fw fa-refresh"
            (click)="refresh()"
          ></button>
        </div>
      </div>
    </div>
    <app-progress-spinner [enabled]="!elements"></app-progress-spinner>
    <div [style.display]="!elements ? 'none' : ''">
      <app-general-grid
        [values]="elements"
        [columns]="columns"
        [loading]="loading"
        [rows]="rows"
        [totalRecords]="totalRecords"
        [filterable]="true"
        [screenName]="screenName"
        [lazy]="true"
        (lazyLoad)="lazyLoad($event)"
        [includeExportCSV]="true"
        [exportFilename]="exportFilename"
        (changeViewEvent)="changeViewEvent($event)"
        (actionButtonEvent)="addEntryEvent($event)"
        scrollHeight="calc(100vh - 276px)"
        [usePaginator]="true"
        [rowsPerPageOptions]="[50, 100, 200]"
        [deviceSize]="getDeviceSize()"
        #grid
      >
      </app-general-grid>
    </div>
  </div>

  <app-mon-logs-add-entry (addEntrySaveEvent)="refresh()">
  </app-mon-logs-add-entry>
</div>
