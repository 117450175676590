import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  signal,
  WritableSignal,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { DialogModule } from 'primeng/dialog';
import { RadioButtonClickEvent } from 'primeng/radiobutton';
import { firstValueFrom } from 'rxjs';
import { CoreModule } from 'src/app/core/core.module';
import { ControlRoomEventReviewEntry } from 'src/app/model/monlogs/control-room-event-review-entry';
import { MonlogsApiService } from 'src/app/services/monlogs-api.service';

@Component({
  selector: 'app-event-review-dialog',
  standalone: true,
  imports: [DialogModule, CommonModule, CoreModule, TranslateModule],
  templateUrl: './event-review-dialog.component.html',
  styleUrl: './event-review-dialog.component.scss',
})
export class EventReviewDialogComponent implements OnInit {
  @Input() monLogId: string;
  @Output() entryChangedEvent = new EventEmitter<any>();

  currentlyEditingEntry: ControlRoomEventReviewEntry;
  form: FormGroup;
  showEntryDialog: boolean = false;
  editorReadOnly: WritableSignal<boolean> = signal(false);
  editorWorking: WritableSignal<boolean> = signal(false);

  constructor(
    private _fb: FormBuilder,
    private _translateService: TranslateService,
    private _monLogApi: MonlogsApiService
  ) {}

  ngOnInit() {
    this.form = this._fb.group({
      note: [null, Validators.required],
      controlRoomInvolved: [false],
    });
  }

  cancelEntryForm(): void {
    this.showEntryDialog = false;
    this.currentlyEditingEntry = null;
  }

  validateForm() {
    this.form.updateValueAndValidity();
  }

  processEntry() {
    this.editorWorking.set(true);
    if (this.currentlyEditingEntry) {
      // Edit mode
      this._monLogApi
        .updateCREREntry(this.currentlyEditingEntry.id, {
          note: this.form.get('note').value,
          controlRoomInvolved: this.form.get('controlRoomInvolved').value,
        })
        .subscribe({
          next: (editedEntry) => {
            this.entryChangedEvent.emit();
            this.showEntryDialog = false;
            this.editorWorking.set(false);
          },
          error: (error) => {
            console.error(error);
            this.editorWorking.set(false);
          },
        });
    } else {
      // Create mode
      this._monLogApi
        .createCREREntry(this.monLogId, {
          note: this.form.get('note').value,
          controlRoomInvolved: this.form.get('controlRoomInvolved').value,
        })
        .subscribe({
          next: (newEntry) => {
            this.entryChangedEvent.emit();
            this.showEntryDialog = false;
            this.editorWorking.set(false);
          },
          error: (error) => {
            console.error(error);
            // show alert message
            this.editorWorking.set(false);
          },
        });
    }
  }

  async beginEditting(entry: ControlRoomEventReviewEntry | null) {
    this.showEntryDialog = true;

    setTimeout(async () => {
      if (!entry) {
        // No entry passed in, setup new form defaults
        const defaultText = await firstValueFrom(
          this._translateService.get('MONLOG.DEFAULT_TEXT.CRER_NOT_INVOLVED')
        );
        this.form.patchValue({
          note: defaultText,
          controlRoomInvolved: false,
        });
        this.setNoteEnabled(false);
        this.form.updateValueAndValidity();
      } else {
        // Setup editting
        this.currentlyEditingEntry = entry;
        this.form.patchValue({
          note: entry.note,
          controlRoomInvolved: entry.controlRoomInvolved,
        });
        this.setNoteEnabled(entry.controlRoomInvolved);
      }
    }, 50);
  }

  setNoteEnabled(enabled: boolean): void {
    this.editorReadOnly.set(!enabled);
  }

  async crInvolvedChanged($event: RadioButtonClickEvent) {
    const value: boolean = $event.value;
    if (value) {
      this.form.patchValue({
        note: '',
      });
      this.setNoteEnabled(true);
    } else {
      const defaultText = await firstValueFrom(
        this._translateService.get('MONLOG.DEFAULT_TEXT.CRER_NOT_INVOLVED')
      );
      this.form.patchValue({
        note: defaultText,
      });
      this.setNoteEnabled(false);
    }
    this.form.updateValueAndValidity();
  }
}
