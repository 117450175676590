import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ProgressSpinnerModule } from 'primeng/progressspinner';

import { AdminModule } from '../admin/admin.module';
import { CoreModule } from '../core/core.module';
import { MonLogMobileFilterComponent } from './components/mon-log-mobile-filter.component';
import { MonLogsEntryComponent } from './components/mon-logs-entry.component';
import { MonLogsAddEntryComponent } from './components/mon-logs-add-entry.component';
import { McShiftTurnoverContainerComponent } from './containers/mc-shift-turnover-container.component';
import { McShiftTurnoverDetailComponent } from './containers/mc-shift-turnover-detail.component';
import { McShiftTurnoverPrintContainerComponent } from './containers/mc-shift-turnover-print-container.component';
import { MonLogPrintContainerComponent } from './containers/mon-log-print-container.component';
import { MonlogsAllLogsSearchContainerComponent } from './containers/monlogs-all-logs-search-container.component';
import { MonlogsDetailContainerComponent } from './containers/monlogs-detail-container.component';
import { MonlogsSearchContainerComponent } from './containers/monlogs-search-container.component';
import { MonlogsRoutingModule } from './monlogs-routing.module';
import { EventReviewEntryComponent } from './components/event-review-entry/event-review-entry.component';
import { EventReviewDialogComponent } from './components/event-review-dialog/event-review-dialog.component';

@NgModule({
  declarations: [
    MonlogsSearchContainerComponent,
    MonlogsDetailContainerComponent,
    MonLogsEntryComponent,
    MonLogsAddEntryComponent,
    MonlogsAllLogsSearchContainerComponent,
    McShiftTurnoverContainerComponent,
    McShiftTurnoverDetailComponent,
    MonLogPrintContainerComponent,
    McShiftTurnoverPrintContainerComponent,
    MonLogMobileFilterComponent,
  ],
  imports: [
    CommonModule,
    MonlogsRoutingModule,
    CoreModule,
    AdminModule,
    ProgressSpinnerModule,
    EventReviewEntryComponent,
    EventReviewDialogComponent,
    TranslateModule.forChild({}),
  ],
  exports: [
    MonlogsSearchContainerComponent,
    MonlogsDetailContainerComponent,
    MonLogsEntryComponent,
    MonLogsAddEntryComponent,
    MonlogsAllLogsSearchContainerComponent,
    McShiftTurnoverContainerComponent,
    McShiftTurnoverDetailComponent,
    MonLogPrintContainerComponent,
    McShiftTurnoverPrintContainerComponent,
    MonLogMobileFilterComponent,
  ],
})
export class MonlogsModule {}
