import { Column } from '../../model/common/column';
import { SelectItem } from 'primeng/api';

export function getMonLogColumns(statusNames: SelectItem[]): Column[] {
  return [
    {
      field: 'indicators',
      header: 'COMMON.LABEL.EMPTY_COL_HEADER',
      formatPattern: 'icons',
      type: 'icons',
      width: '3%',
      filterable: false,
    },
    {
      field: 'addEntry',
      header: 'MONLOG.LABEL.ADD_ENTRY',
      mobileHeader: 'MONLOG.LABEL.ADD_ENTRY',
      formatPattern: "actionButton",
      actionTextField: "actionText",
      width: '4%',
      sortable: false,
      filterable: false,
    },
    {
      field: 'logId',
      header: 'MONLOG.LABEL.LOG_ID',
      formatPattern: 'text',
      width: '8%',
      filterable: true,
      sortable: true,
      matchMode: 'contains',
    },
    {
      field: 'facilityName',
      header: 'LOCATION.LABEL.FACILITY_NAME',
      mobileHeader: 'LOCATION.LABEL.FACILITY_NAME',
      formatPattern: 'text',
      width: '10%',
      sortable: true,
      filterable: true,
    },
    {
      field: 'facilityUid',
      header: 'LOCATION.LABEL.FACILITY_UID',
      mobileHeader: 'LOCATION.LABEL.FACILITY_UID',
      formatPattern: 'text',
      width: '5%',
      sortable: true,
      filterable: true,
    },
    {
      field: 'callType',
      header: 'MONLOG.LABEL.CALL_TYPE',
      mobileHeader: 'MONLOG.LABEL.CALL_TYPE',
      formatPattern: 'text',
      width: '20%',
      sortable: true,
      filterable: true,
    },
    {
      field: 'statusSummary',
      header: 'MONLOG.LABEL.STATUS_SUMMARY',
      mobileHeader: 'MONLOG.LABEL.STATUS_SUMMARY',
      formatPattern: 'text',
      width: '35%',
      sortable: true,
      filterable: true,
    },
    {
      field: 'primaryContact',
      header: 'MONLOG.LABEL.PRIMARY_CONTACT',
      mobileHeader: 'MONLOG.LABEL.PRIMARY_CONTACT',
      formatPattern: 'text',
      width: '14%',
      sortable: true,
      filterable: true,
    },
    {
      field: 'statusName',
      header: 'MONLOG.LABEL.STATUS',
      mobileHeader: 'MONLOG.LABEL.STATUS',
      formatPattern: 'text',
      type: 'dropdown',
      width: '5%',
      sortable: true,
      filterable: true,
      options: statusNames,
    }
  ];
}
