import {
  A_AND_E_LOG_CATEGORY,
  M_AND_R_LOG_CATEGORY,
  SCADA_ALARMS,
  TOWER_LIGHTS,
  TRANSDUCER_ALARM,
} from 'src/app/constants/monlog-constants';
import { MonLog } from 'src/app/model/monlogs/mon-log';
import { isNullOrUndefined } from 'src/app/utils/utils';

export function hasMonLogWorkTypes(log: MonLog) {
  let retVal = false;
  if (log && log.MonLogWorkTypes && log.MonLogWorkTypes.length > 0) {
    retVal = true;
  }
  return true;
}

export function isAnAlarm(log: MonLog) {
  let retVal = false;
  if (
    log.MonLogTypes.some(
      (type) =>
        type.label.toUpperCase() === SCADA_ALARMS.toUpperCase() ||
        type.label.toUpperCase() === TOWER_LIGHTS.toUpperCase() ||
        type.label.toUpperCase() === TRANSDUCER_ALARM.toUpperCase()
    )
  ) {
    retVal = true;
  }

  return retVal;
}

export function getMonLogHeader(log: MonLog) {
  let header = '';

  if (!isNullOrUndefined(log) && isNullOrUndefined(log.id)) {
    header = '*New';
    if (log.logCategory === M_AND_R_LOG_CATEGORY) {
      header = '*New M&R';
    } else if (log.logCategory === A_AND_E_LOG_CATEGORY) {
      header = '*New A&E';
    }
  } else {
    if (log && log.Facility && log.Facility.facilityUid) {
      header += log.logId + ' (' + log.Facility.facilityUid + ')';
    } else if (log.pipeline) {
      header += log.logId + ' (' + log.pipeline + ')';
    } else if (log && log.logId) {
      header += log.logId;
    }
  }

  return header;
}
