<p-dialog
  header="{{ 'MONLOG.LABEL.ADD_ENTRY_LONG' | translate }}"
  [(visible)]="showEntryDialog"
  showEffect="fade"
  [modal]="true"
  [transitionOptions]="'0ms'"
  (onHide)="cancelEntryForm()"
  [style]="{ width: '1000px' }"
>
  @if (!autoResolveExecuting) {
  <form [formGroup]="addEntryForm" class="form">
    <div id="add-location-create-form">
      <div class="panel">
        <div class="no-padding-top-bottom form-row">
          <div class="row-item">
            <span>
              <p-autoComplete
                [suggestions]="filteredContacts"
                [dropdown]="true"
                (completeMethod)="contactSearch($event)"
                appendTo="body"
                optionLabel="primaryFullName"
                formControlName="addEntryFormContact"
                (onSelect)="
                  findContactAndAddToEntryForm($event.value.primaryId)
                "
                [forceSelection]="true"
                [virtualScroll]="true"
                [virtualScrollItemSize]="34"
              ></p-autoComplete>
            </span>
          </div>
          <div class="row-item">
            @for (c of primaryContacts; track $index) {
            <span class="mr-1">
              <button
                pButton
                [label]="c.primaryFullName"
                (click)="findContactAndAddToEntryForm(c.primaryId)"
              ></button>
            </span>
            }
          </div>
        </div>

        <div class="form-row">
          <div class="row-item">
            <span class="label">{{
              "MONLOG.LABEL.ACTIVITY_DATE" | translate
            }}</span>
            <span class="content">
              <p-calendar
                [showTime]="true"
                formControlName="entryTime"
                appendTo="body"
                [showSeconds]="true"
              >
              </p-calendar>
            </span>
            <span class="label">{{
              "MONLOG.LABEL.CURRENT_TIME_DEFAULT" | translate
            }}</span>
          </div>
        </div>
        <div class="no-padding-top-bottom form-body">
          <div class="no-padding-top-bottom">
            <span class="label"
              >{{ "MONLOG.LABEL.PLEASE_INSERT_STATUS" | translate }}:</span
            >
          </div>

          @if(showEntryDialog) {
          <p-editor
            formControlName="statusUpdate"
            [style]="{ height: '300px' }"
            (onTextChange)="validateForm()"
          >
          </p-editor>
          }
        </div>

        <div class="no-padding-top-bottom label">
          {{ "MONLOG.LABEL.ENTRY_LABEL" | translate }}
        </div>

        @if (showGasReleasePopupMessage) {
        <div>
          <p-message
            severity="error"
            text="{{
              'MONLOG.MESSAGES.ERROR.NO_GAS_RELEASE_TYPE_FOUND_MSG_ALT'
                | translate
            }}"
          ></p-message>
        </div>
        } @if (showCalloutPopupMessage) {
        <div>
          <p-message
            severity="error"
            text="{{ 'MONLOG.MESSAGES.ERROR.OUTSTANDING_CALLOUT' | translate }}"
          ></p-message>
        </div>
        } @if (addingAction === true) {
        <div
          class="no-padding-top-bottom label"
          style="display: flex; align-items: center"
        >
          <span
            class="label"
            [translate]="'MONLOG.LABEL.ACTION_RESOLUTION'"
          ></span>
          <span style="display: flex; align-items: center">
            <div style="margin-left: 8px">
              <p-radioButton
                name="isResolution"
                [value]="true"
                label="{{ 'COMMON.LABEL.BUTTONS.YES' | translate }}"
                formControlName="isResolution"
              ></p-radioButton>
            </div>
            <div style="margin-left: 8px">
              <p-radioButton
                name="isResolution"
                [value]="false"
                label="{{ 'COMMON.LABEL.BUTTONS.NO' | translate }}"
                formControlName="isResolution"
              ></p-radioButton>
            </div>
          </span>
        </div>
        } @if (addingAction === true &&
        addEntryForm.controls['isResolution'].value === true) {
        <div
          class="no-padding-top-bottom label"
          style="display: flex; align-items: center"
        >
          <span class="label" [translate]="'MONLOG.LABEL.NEED_REVIEW'"></span>
          <span style="display: flex; align-items: center">
            <div style="margin-left: 8px">
              <p-radioButton
                name="needReview"
                [value]="true"
                label="{{ 'COMMON.LABEL.BUTTONS.YES' | translate }}"
                formControlName="needReview"
              ></p-radioButton>
            </div>
            <div style="margin-left: 8px">
              <p-radioButton
                name="needReview"
                [value]="false"
                label="{{ 'COMMON.LABEL.BUTTONS.NO' | translate }}"
                formControlName="needReview"
              ></p-radioButton>
            </div>
          </span>
        </div>
        }

        <div class="modal-buttons">
          <div class="offset-6">
            <button
              pButton
              label="{{ 'COMMON.LABEL.BUTTONS.OK' | translate }}"
              icon="fa fa-fw fa-check"
              (click)="addEntryToMonitoringLog()"
              [disabled]="!this.addEntryForm.valid"
            ></button>
          </div>
          <div style="margin-left: 8px">
            <button
              pButton
              label="{{ 'COMMON.LABEL.BUTTONS.CANCEL' | translate }}"
              icon="fa fa-fw fa-ban"
              (click)="cancelEntryForm()"
            ></button>
          </div>
        </div>
      </div>
    </div>
  </form>
  } @else {
  <div class="resolution-display">
    <span>Resolving Log</span>
    <p-progressSpinner
      [style]="{ width: '80px', height: '80px' }"
    ></p-progressSpinner>
  </div>
  }
</p-dialog>
