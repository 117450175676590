import { Column } from '../../model/common/column';
import { SelectItem } from 'primeng/api';


export function getShiftTurnoverColumns(availableDistricts: SelectItem[], types: SelectItem[], status: SelectItem[]): Column[] {
  return [
    {
      field: 'shiftDate',
      header: 'CRM.LABEL.TURNOVER_DATE',
      formatPattern: 'calendar',
      type: 'calendar',
      width: '5%',
      sortable: true,
      filterable: false
    },
    {
      field: 'shiftName',
      header: 'CRM.LABEL.SHIFT',
      formatPattern: 'text',
      type: 'dropdown',
      width: '15%',
      sortable: true,
      options: types,
      filterable: true
    },
    {
      field: 'statusLabel',
      header: 'CRM.LABEL.STATUS',
      formatPattern: 'text',
      type: 'dropdown',
      width: '15%',
      sortable: true,
      filterable: true,
      options: status
    },
    {
      field: 'incomingName',
      header: 'CRM.LABEL.INCOMING_ANALYST',
      formatPattern: 'text',
      width: '15%',
      sortable: true,
      filterable: true
    },
    {
      field: 'departingName',
      header: 'CRM.LABEL.DEPARTING_ANALYST',
      formatPattern: 'text',
      width: '15%',
      sortable: true,
      filterable: true
    }
  ];
}
