import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MonLogAlarm } from 'src/app/model/monlogs/mon-log-alarm';
import { MonLogEntry } from 'src/app/model/monlogs/mon-log-entry';

@Component({
  selector: 'app-mon-logs-entry',
  templateUrl: './mon-logs-entry.component.html',
  styleUrls: ['./mon-logs-entry.component.scss'],
})
export class MonLogsEntryComponent {
  @Input() entry: MonLogAlarm | MonLogEntry;
  @Input() writeAccess = false;
  @Output() editEvent = new EventEmitter<any>();
  @Output() deleteEvent = new EventEmitter<any>();

  constructor() {}
}
