import { Component, Output, EventEmitter, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { MenuItem } from 'primeng/api';
import { AutoCompleteCompleteEvent } from 'primeng/autocomplete';
import { Editor } from 'primeng/editor';
import { take } from 'rxjs/operators';
import { AWAITING_REVIEW_CD } from 'src/app/constants/monlog-constants';
import { isNullOrEmptyArray } from 'src/app/core/functions/common-functions';
import { Contact } from 'src/app/model/contacts/contact';
import { MonLog } from 'src/app/model/monlogs/mon-log';
import { ContactApiService } from 'src/app/services/contact-api.service';
import { ContactBuilderService } from 'src/app/services/contact-builder.service';
import { LogAndMessageService } from 'src/app/services/log-and-message.service';
import { MonlogsApiService } from 'src/app/services/monlogs-api.service';
import { MonlogsBuilderService } from 'src/app/services/monlogs-builder.service';
import { isNullOrUndefined } from 'src/app/utils/utils';

@Component({
  selector: 'app-mon-logs-add-entry',
  templateUrl: './mon-logs-add-entry.component.html',
  styleUrls: ['./mon-logs-add-entry.component.scss'],
})
export class MonLogsAddEntryComponent {
  @Output() addEntrySaveEvent = new EventEmitter<any>();

  @ViewChild(Editor, { static: true }) editor: Editor;

  monLog: MonLog;
  addingAction: boolean;
  primaryContacts: MenuItem[];

  showEntryDialog = false;
  addEntryForm: UntypedFormGroup;

  contacts: any[];
  filteredContacts: any[];
  primaryContact: any;

  showGasReleaseType = false;
  showGasReleasePopupMessage = false;
  showCalloutPopupMessage = false;

  autoResolveExecuting = false;

  id: number;
  statusUpdate: string;
  isResolution: boolean;
  isAction: boolean;
  entryTime: Date;
  needReview: boolean;

  constructor(
    protected _translateService: TranslateService,
    protected _logAndMessage: LogAndMessageService,
    private _fb: UntypedFormBuilder,
    private _monLogBuilder: MonlogsBuilderService,
    private _monLogApi: MonlogsApiService,
    protected _contactApi: ContactApiService,
    private _contactBuilder: ContactBuilderService
  ) {}

  ngOnInit() {
    this.addEntryForm = this._fb.group({
      statusUpdate: [null, Validators.required],
      isResolution: false,
      addEntryFormContact: null,
      isAction: false,
      id: null,
      needReview: null,
      entryTime: null,
    });

    this.loadContacts();
  }

  cancelEntryForm() {
    this.addEntryForm.reset();
    this.showEntryDialog = false;
    this.addingAction = false;
  }

  loadContacts() {
    this._contactApi
      .findContacts()
      .pipe(take(1))
      .subscribe(({ data }) => {
        const clone = [...data.getContacts];
        const arr = clone.map((c) => this._contactBuilder.buildContact(c));
        this.contacts = arr;
      });
  }

  /**
   * Used to search for contacts by full name.  This is used for different types of contacts so a flag will determine the appropriate
   * mapping function to use for the data.
   * @param $event
   * @param isInternal
   */
  contactSearch($event: AutoCompleteCompleteEvent, isForInternalLogs = false) {
    let mappingFunction: Function =
      this._monLogBuilder.buildMonLogContactsFromContact;
    if (isForInternalLogs === true) {
      mappingFunction =
        this._monLogBuilder.buildMonLogInternalContactFromContact;
    }

    const query = {
      firstName: $event.query.toLowerCase(),
    };

    const sort = {
      firstName: 'ASC',
    };
    this._contactApi
      .queryForContactDropdown(999999, 1, query, sort)
      .pipe(take(1))
      .subscribe(({ data }) => {
        const clone = Object.assign({}, data);
        this.filteredContacts = clone.queryForContactDropdown.items.map((c) =>
          mappingFunction(c)
        );
      });
  }

  findContactAndAddToEntryForm(contactId: string) {
    this.addEntryForm.patchValue({
      addEntryFormContact: null,
    });
    const arr = this.contacts.filter((_c) => _c.id === contactId);
    if (arr.length > 0) {
      const _contact: Contact = arr[0];
      this._translateService
        .get('MONLOG.LABEL.CONTACT_NOTE_LABEL', {
          firstName: _contact.firstName,
          lastName: _contact.lastName,
          jobRole: _contact.jobRole,
          company: _contact.companyAbbreviation,
          city: _contact.city,
        })
        .pipe(take(1))
        .subscribe((message) => {
          let currVal = this.addEntryForm.controls['statusUpdate'].value;
          if (!currVal) {
            currVal = '';
          }
          let update = '';
          if (currVal.endsWith('</p>')) {
            const nmsg = message.replace('<p>', '');
            const ncurr = currVal.replace('</p>', '');
            update = ncurr + nmsg;
          } else {
            update = currVal + message;
          }

          this.addEntryForm.patchValue({
            statusUpdate: update,
          });
          this.editor
            .getQuill()
            .setSelection(
              this.addEntryForm.controls['statusUpdate'].value.length
            );
        });
    }
  }

  validateForm() {
    this.addEntryForm.updateValueAndValidity();
  }

  addEntryToMonitoringLog() {
    const raw = this.addEntryForm.getRawValue();
    if (raw.isAction) {
      this.processMonLogEntry(raw);
    } else {
      this.processMonLogAlarm(raw);
    }

    this.addEntryForm.reset();
    this.showEntryDialog = false;
  }

  private processMonLogEntry(raw: any) {
    if (!raw.id) {
      this._monLogApi
        .createMonLogEntry(this.monLog.id, {
          isResolution: raw.isResolution ?? false,
          isAwaitingReview: raw.needReview ?? false,
          note: raw.statusUpdate,
          time: raw.entryTime ?? new Date().toISOString(),
        })
        .subscribe(
          (entry) => {
            this.addEntrySaveEvent.emit(entry);

            this._logAndMessage.translateToSuccessMessage({
              bodyKey: 'MONLOG.MESSAGES.SUCCESS.CREATE_MON_LOG_ENTRY',
              bodyParams: { logId: this.monLog.logId },
              headerKey: 'MONLOG.MESSAGES.HEADERS.CREATE_LOG_ENTRY',
            });
          },
          (error) => {
            this._logAndMessage.translateToErrorAlert({
              headerKey: 'MONLOG.MESSAGES.HEADERS.FAILED_CREATE_LOG_ENTRY',
              bodyKey: 'MONLOG.MESSAGES.ERROR.CREATE_MON_LOG_ENTRY',
            });
            console.error(error);
          }
        );
    } else {
      this._monLogApi
        .updateMonLogEntry(raw.id, {
          isResolution: raw.isResolution ?? false,
          isAwaitingReview: raw.needReview ?? false,
          note: raw.statusUpdate,
          time: raw.entryTime ?? new Date().toISOString(),
        })
        .subscribe(
          (entry) => {
            this.addEntrySaveEvent.emit(entry);

            this._logAndMessage.translateToSuccessMessage({
              bodyKey: 'MONLOG.MESSAGES.SUCCESS.UPDATE_MON_LOG_ENTRY',
              bodyParams: { logId: this.monLog.logId },
              headerKey: 'MONLOG.MESSAGES.HEADERS.UPDATE_LOG_ENTRY',
            });
          },
          (error) => {
            this._logAndMessage.translateToErrorAlert({
              headerKey: 'MONLOG.MESSAGES.HEADERS.FAILED_UPDATE_LOG_ENTRY',
              bodyKey: 'MONLOG.MESSAGES.ERROR.UPDATE_MON_LOG_ENTRY',
            });
            console.error(error);
          }
        );
    }
  }

  private processMonLogAlarm(raw: any) {
    if (!raw.id) {
      this._monLogApi
        .createMonLogAlarm(this.monLog.id, {
          note: raw.statusUpdate,
          time: raw.entryTime ?? new Date().toISOString(),
        })
        .subscribe(
          (entry) => {
            this.addEntrySaveEvent.emit(entry);
          },
          (error) => {
            this._logAndMessage.translateToErrorAlert({
              headerKey: 'MONLOG.MESSAGES.HEADERS.FAILED_CREATE_LOG_ENTRY',
              bodyKey: 'MONLOG.MESSAGES.ERROR.CREATE_MON_LOG_ENTRY',
            });
            console.error(error);
          }
        );
    } else {
      this._monLogApi
        .updateMonLogAlarm(raw.id, {
          note: raw.statusUpdate,
          time: raw.entryTime ?? new Date().toISOString(),
        })
        .subscribe(
          (entry) => {
            this.addEntrySaveEvent.emit(entry);
          },
          (error) => {
            this._logAndMessage.translateToErrorAlert({
              headerKey: 'MONLOG.MESSAGES.HEADERS.FAILED_UPDATE_LOG_ENTRY',
              bodyKey: 'MONLOG.MESSAGES.ERROR.UPDATE_MON_LOG_ENTRY',
            });
            console.error(error);
          }
        );
    }
  }

  toggleResolutionFields() {
    if (!this.checkResolutionForGasRelease(false)) {
      this.showGasReleasePopupMessage = true;
      this.addEntryForm.controls['isResolution'].disable();
      this.addEntryForm.controls['needReview'].disable();
    } else if (this.areThereOutstandingCallouts()) {
      this.showCalloutPopupMessage = true;
      this.addEntryForm.controls['isResolution'].disable();
      this.addEntryForm.controls['needReview'].disable();
    } else {
      this.showGasReleasePopupMessage = false;
      this.showCalloutPopupMessage = false;
      this.addEntryForm.controls['isResolution'].enable();
      this.addEntryForm.controls['needReview'].enable();
    }
  }

  private checkResolutionForGasRelease(showMessage = true) {
    if (this.showGasReleaseType && !this.monLog.gasReleaseType) {
      if (showMessage) {
        this._logAndMessage.translateToErrorMessage({
          headerKey: 'MONLOG.MESSAGES.HEADERS.NO_GAS_RELEASE_TYPE_FOUND',
          bodyKey: 'MONLOG.MESSAGES.ERROR.NO_GAS_RELEASE_TYPE_FOUND_MSG',
        });
      }
      return false;
    }
    return true;
  }

  areThereOutstandingCallouts(): boolean {
    if (
      !isNullOrEmptyArray(this.monLog.MonLogCallouts) &&
      !isNullOrEmptyArray(
        this.monLog.MonLogCallouts.filter((co) => isNullOrUndefined(co.endDt))
      )
    ) {
      return true;
    } else {
      return false;
    }
  }

  setForm(
    monLog: MonLog,
    isAction = false,
    fromSearchPage = false,
    monLogId = null,
    autoResolve = false
  ) {
    if (!fromSearchPage) {
      this.monLog = monLog;
      this.buildNewEntryForm(isAction);

      if (autoResolve) {
        this.autoResolveExecuting = true;
        this.addResolutionAndContactEntries();
      }
    } else {
      this._monLogApi
        .getMonitoringLogWithAlarms(monLogId)
        .pipe(take(1))
        .subscribe(
          ({ data }) => {
            const clone = Object.assign({}, data);
            if (clone.getMonitoringLogWithAlarms) {
              this.monLog = this._monLogBuilder.buildMonLog(
                clone.getMonitoringLogWithAlarms
              );
              this.buildNewEntryForm(isAction);

              if (autoResolve) {
                this.autoResolveExecuting = true;
                this.addResolutionAndContactEntries();
              }
            } else {
              // nothing found
              this._logAndMessage.errorLogOnly(
                'Could not find mon log with ID: ' + monLogId
              );
            }
          },
          (error) => {
            // nothing found
            this._logAndMessage.errorLogOnly(
              'Error finding mon log with ID: ' + monLogId
            );
          }
        );
    }
  }

  buildNewEntryForm(isAction) {
    const contacts = Object.assign(
      [],
      this.monLog.MonLogContacts.map((c, index) => ({
        primaryId: c.primaryId,
        primaryFullName: c.primaryFullName,
        index: index,
      }))
    );
    this.primaryContacts = [...contacts];
    this.addingAction = isAction;
    this.addEntryForm.patchValue({
      isAction: this.addingAction,
      isResolution: false,
    });
    this.toggleResolutionFields();

    this.showEntryDialog = true;
  }

  setFormForEdit($event, isAction = false, monLog: MonLog = null) {
    if (monLog !== null) {
      this.monLog = monLog;
      this.buildEditEntryForm($event, isAction);
    } else {
      this._monLogApi
        .getMonitoringLogWithAlarms($event.id)
        .pipe(take(1))
        .subscribe(
          ({ data }) => {
            const clone = Object.assign({}, data);
            if (clone.getMonitoringLogWithAlarms) {
              this.monLog = this._monLogBuilder.buildMonLog(
                clone.getMonitoringLogWithAlarms
              );
              this.buildEditEntryForm($event, isAction);
            } else {
              // nothing found
              this._logAndMessage.errorLogOnly(
                'Could not find mon log with ID: ' + $event.id
              );
            }
          },
          (error) => {
            // nothing found
            this._logAndMessage.errorLogOnly(
              'Error finding mon log with ID: ' + $event.id
            );
          }
        );
    }
  }

  buildEditEntryForm($event, isAction) {
    this.addEntryForm.patchValue({
      id: $event.id,
      statusUpdate: $event.note,
      isResolution: $event.isResolution,
      isAction: isAction,
      entryTime: $event.time,
      needReview:
        $event.isResolution &&
        !isNullOrUndefined(this.monLog.logStatus) &&
        this.monLog.logStatus === AWAITING_REVIEW_CD,
    });
    this.addingAction = isAction;
    this.toggleResolutionFields();

    this.showEntryDialog = true;
  }

  addResolutionAndContactEntries() {
    let label = 'MONLOG.LABEL.MAINTENANCE_ENTRY_COMPLETE';

    const firstContact = this.monLog.MonLogContacts[0];

    if (!firstContact || !firstContact.primaryFullName) {
      setTimeout(() => {
        this.cancelEntryForm();
        this.autoResolveExecuting = false;
      }, 50); // delay 50ms to make sure the dialog is open before we close it from the error

      this._logAndMessage.translateToErrorMessage({
        bodyKey: 'MONLOG.MESSAGES.ERROR.NO_PRIMARY_CONTACT_FOUND',
        bodyParams: { logId: this.monLog.logId },
        headerKey:
          'MONLOG.MESSAGES.HEADERS.ERROR_ADDING_RESOLUTION_CONTACT_LOG',
      });

      return;
    }

    this._translateService
      .get(label, {
        primaryContact: firstContact.primaryFullName,
        facilityName: this.monLog.Facility.facilityName,
        facilityUid: this.monLog.Facility.facilityUid,
        workType: this.monLog.MonLogWorkTypes.map((i) => i.label).join(', '),
      })
      .pipe(take(1))
      .subscribe((message) => {
        if (this.areThereOutstandingCallouts()) {
          this._logAndMessage.translateToErrorMessage({
            headerKey: 'MONLOG.MESSAGES.HEADERS.RESOLVE_LOG',
            bodyKey: 'MONLOG.MESSAGES.ERROR.OUTSTANDING_CALLOUT',
          });
          return false;
        }

        this.monLog.logStatus = 'R';

        let id = this.addEntryForm.controls['id'].value;
        let entryTime = this.addEntryForm.controls['entryTime'].value;

        this.addEntryForm.patchValue({
          id: id,
          statusUpdate: message,
          isResolution: true,
          isAction: true,
          entryTime: entryTime ?? new Date(),
          needReview: false,
        });

        const raw = this.addEntryForm.getRawValue();

        this._monLogApi
          .createMonLogEntry(this.monLog.id, {
            isResolution: raw.isResolution ?? false,
            isAwaitingReview: raw.needReview ?? false,
            note: raw.statusUpdate,
            time: raw.entryTime ?? new Date().toISOString(),
          })
          .subscribe(
            (entry) => {
              this.addControllerEntry();
            },
            (error) => console.error(error)
          );
      });
  }

  addControllerEntry() {
    let controller = null;
    if (this.monLog.internalOne) {
      controller = this.monLog.internalOne;
    } else if (this.monLog.internalTwo) {
      controller = this.monLog.internalTwo;
    } else {
      setTimeout(() => {
        this.cancelEntryForm();
        this.autoResolveExecuting = false;
      }, 50); // delay 50ms to make sure the dialog is open before we close it from the error

      this._logAndMessage.translateToErrorMessage({
        bodyKey: 'MONLOG.MESSAGES.ERROR.NO_INTERNAL_CONTACTS_FOUND',
        bodyParams: { logId: this.monLog.logId },
        headerKey: 'MONLOG.MESSAGES.HEADERS.ERROR_ADDING_INTERNAL_CONTACT_LOG',
      });

      return;
    }

    if (controller) {
      this._translateService
        .get('MONLOG.LABEL.CONTROLLER_ENTRY', {
          primaryContact: controller.fullName,
          facilityName: this.monLog.Facility.facilityName,
          facilityUid: this.monLog.Facility.facilityUid,
        })
        .pipe(take(1))
        .subscribe((message) => {
          let id = this.addEntryForm.controls['id'].value;
          let entryTime = this.addEntryForm.controls['entryTime'].value;

          this.addEntryForm.patchValue({
            id: id,
            statusUpdate: message,
            isResolution: false,
            isAction: true,
            entryTime: entryTime ?? new Date(),
            needReview: false,
          });

          const raw = this.addEntryForm.getRawValue();

          this._monLogApi
            .createMonLogEntry(this.monLog.id, {
              isResolution: raw.isResolution ?? false,
              isAwaitingReview: raw.needReview ?? false,
              note: raw.statusUpdate,
              time: raw.entryTime ?? new Date().toISOString(),
            })
            .subscribe(
              (entry) => {
                this.addEntrySaveEvent.emit(entry);
                this.cancelEntryForm();

                this._logAndMessage.translateToSuccessMessage({
                  bodyKey: 'MONLOG.MESSAGES.SUCCESS.RESOLVE_LOG',
                  bodyParams: { logId: this.monLog.logId },
                  headerKey: 'MONLOG.MESSAGES.HEADERS.RESOLVE_LOG',
                });
              },
              (error) => console.error(error)
            );
        });
    }
  }
}
