import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { MonlogsSearchContainerComponent } from './containers/monlogs-search-container.component';
import { MonlogsDetailContainerComponent } from './containers/monlogs-detail-container.component';
import { AuthGuard } from '../guards/auth.guard';
import { MonlogsAllLogsSearchContainerComponent } from './containers/monlogs-all-logs-search-container.component';
import { CanDeactivateGuard } from '../guards/deactivate.guard';
import { McShiftTurnoverContainerComponent } from './containers/mc-shift-turnover-container.component';
import { McShiftTurnoverDetailComponent } from './containers/mc-shift-turnover-detail.component';
import { MonLogPrintContainerComponent } from './containers/mon-log-print-container.component';
import { McShiftTurnoverPrintContainerComponent } from './containers/mc-shift-turnover-print-container.component';

const routes: Routes = [
  { path: 'logs', component: MonlogsSearchContainerComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'logs/details/:id', component: MonlogsDetailContainerComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'logs/details', component: MonlogsDetailContainerComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
  { path: 'logs/all', component: MonlogsAllLogsSearchContainerComponent, canActivate: [AuthGuard] },
  { path: 'logs/print/:id', component: MonLogPrintContainerComponent, canActivate: [AuthGuard]},
  { path: 'turnovers', component: McShiftTurnoverContainerComponent, canActivate: [AuthGuard] },
  {
    path: 'turnovers/details/:id', component: McShiftTurnoverDetailComponent, canActivate: [AuthGuard],
    canDeactivate: [CanDeactivateGuard]
  },
  {
    path: 'turnovers/print/:id', component: McShiftTurnoverPrintContainerComponent, canActivate: [AuthGuard]
  },
  { path: 'turnovers/details', component: McShiftTurnoverDetailComponent, canActivate: [AuthGuard], canDeactivate: [CanDeactivateGuard] },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class MonlogsRoutingModule { }
