import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { SelectItem } from 'primeng/api';
import { interval, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { MC_ST_DETAIL_CONTAINER } from 'src/app/constants/common.constants';
import { SearchContainerComponent } from 'src/app/core/containers/search-container/search-container.component';
import { TabService } from 'src/app/core/services/tab.service';
import { ShiftTurnover } from 'src/app/model/shift/shift-turnover';
import { AuthService } from 'src/app/services/auth.service';
import { AuthApiService } from 'src/app/services/auth-api.service';
import { DeviceService } from 'src/app/services/device.service';
import { LoadingService } from 'src/app/services/loading.service';
import { ShiftTurnoverApiService } from 'src/app/services/shift-turnover-api.service';
import { ShiftTurnoverBuilderService } from 'src/app/services/shift-turnover-builder.service';
import { ShiftTurnoverTableService } from 'src/app/services/shift-turnover-table.service';
import { getShiftTurnoverColumns } from '../definitions/shift-turnover-table-definition';

@Component({
  selector: 'app-mc-shift-turnover-container',
  templateUrl: './mc-shift-turnover-container.component.html',
  styleUrls: ['./mc-shift-turnover-container.component.scss'],
})
export class McShiftTurnoverContainerComponent
  extends SearchContainerComponent<ShiftTurnover>
  implements OnInit, OnDestroy
{
  allShiftTurnovers: any[];
  canCreate = false;
  additionalFilters = [];
  actions: any[] = [];
  startDate: Date;
  endDate: Date;
  groups: any[];
  mcGroupId: string;
  shiftTypes: SelectItem[];
  statusOptions: SelectItem[];
  init = false;
  private _isRefreshing = false;
  refreshSubscription: Subscription;

  constructor(
    private _translateService: TranslateService,
    protected _deviceService: DeviceService,
    protected _cdRef: ChangeDetectorRef,
    private _shiftTurnoverApi: ShiftTurnoverApiService,
    private _shiftTurnoverTableService: ShiftTurnoverTableService,
    private _shiftTurnoverBuilder: ShiftTurnoverBuilderService,
    private _authApi: AuthApiService,
    protected _auth: AuthService,
    protected _loader: LoadingService
  ) {
    super(_deviceService, _loader, _auth, _cdRef);
  }

  ngOnInit() {
    this.setTableService(this._shiftTurnoverTableService);
    if (this._loader.isLoaded()) {
      this.initialize();
    } else {
      this._loader.loadingFinishedEvent.pipe(take(1)).subscribe(() => {
        this.initialize();
      });
    }

    const refreshInterval = interval(60000);
    this.refreshSubscription = refreshInterval.subscribe(() => {
      this.refresh(false);
    });
  }

  initialize() {
    this.statusOptions = [
      { label: 'Choose', value: null },
      { label: 'New', value: 'N' },
      { label: 'Departing Complete', value: 'DC' },
      { label: 'Resolved', value: 'R' },
    ];
    this._translateService
      .get('MONLOG.SCREEN.SHIFT_TURNOVER')
      .subscribe((label) => {
        TabService.getInstance().updateActiveTabLabel(label);
      });

    this.canCreate = this._authApi.doesUserHaveAllClaimsFromList([
      'CreateMCTurnovers',
    ]);

    this._shiftTurnoverApi
      .loadAvailableGroups()
      .pipe(take(1))
      .subscribe(({ data }) => {
        this.groups = data.getShiftGroups;
        this.mcGroupId = this.groups.find(
          (g) => g.name === 'Monitoring Center'
        ).id;
        this.applyPreviousData();
        this.applyPreviousFilters();
      });

    this._shiftTurnoverApi
      .loadAvailableTypes()
      .pipe(take(1))
      .subscribe(({ data }) => {
        this.shiftTypes = [
          { label: 'Choose', value: null },
          ...data.getShiftTypes
            .filter((d) => d.ShiftGroup.name === 'Monitoring Center')
            .map((d) => ({ label: d.name, value: d.id })),
        ];
        this.setupTable();
      });
  }

  setupTable() {
    this.columns = getShiftTurnoverColumns(
      [],
      this.shiftTypes,
      this.statusOptions
    );

    const existingFilters = this._shiftTurnoverTableService.getLastQuery();
    if (!existingFilters || !existingFilters['startDate']) {
      this.goToToday(false);
    } else {
      this.startDate = existingFilters['startDate'];
      this.endDate = existingFilters['endDate'];
    }

    this.init = true;
  }

  ngOnDestroy(): void {
    this.refreshSubscription.unsubscribe();
  }

  /**
   * Method called when the user hits the clear button.
   */
  clearScreen() {
    this.lazyLoad({ lazy: {} });
    this.grid.resetTable();
    this._shiftTurnoverTableService.clearResults();
    this.elements = [];
  }

  goToToday(invoke = true) {
    const today = new Date();
    this.startDate = new Date(
      today.getFullYear(),
      today.getMonth(),
      today.getDate(),
      6,
      0,
      0
    );

    this.endDate = new Date();
    this.endDate.setDate(this.startDate.getDate() + 1);
    this.endDate = new Date(
      this.endDate.getFullYear(),
      this.endDate.getMonth(),
      this.endDate.getDate(),
      5,
      59,
      59
    );
    if (invoke) {
      this.dateUpdate();
    }
  }

  previousDay() {
    this.startDate = new Date(this.startDate.valueOf());
    this.startDate.setDate(this.startDate.getDate() - 1);
    this.startDate.setHours(6);
    this.startDate.setMinutes(0);
    this.startDate.setSeconds(0);

    this.endDate = new Date(this.startDate.valueOf());
    this.endDate.setDate(this.startDate.getDate() + 1);
    this.endDate.setHours(5);
    this.endDate.setMinutes(59);
    this.endDate.setSeconds(59);
    this.dateUpdate();
  }

  nextDay() {
    this.startDate = new Date(this.endDate.valueOf());
    this.startDate.setDate(this.startDate.getDate());
    this.startDate.setHours(6);
    this.startDate.setMinutes(0);
    this.startDate.setSeconds(0);

    this.endDate.setHours(5);
    this.endDate.setMinutes(59);
    this.endDate.setSeconds(59);
    this.endDate.setDate(this.endDate.getDate());
    this.dateUpdate();
  }

  dateUpdate(fromEnd = false) {
    if (this.endDate < this.startDate && fromEnd === false) {
      this.endDate = new Date(this.startDate.valueOf());
      this.endDate.setDate(this.endDate.getDate() + 1);
      this.endDate.setHours(5);
      this.endDate.setMinutes(59);
      this.endDate.setSeconds(59);
    } else if (this.endDate < this.startDate && fromEnd === true) {
      this.startDate = new Date(this.endDate.valueOf());
      this.startDate.setDate(this.startDate.getDate() - 1);
      this.startDate.setHours(6);
      this.startDate.setMinutes(0);
      this.startDate.setSeconds(0);
    }

    this.lazyLoad({ lazy: this._shiftTurnoverTableService.getLastLazyLoad() });
  }

  changeViewEvent($event) {
    const tab = TabService.getInstance().buildNewTab(
      MC_ST_DETAIL_CONTAINER,
      true,
      null,
      $event.data.id
    );
    TabService.getInstance().openTab(tab);
  }

  handleLazyLoad(req, $event, filters) {
    if (!this.startDate) {
      this.goToToday(false);
    }

    const query = {
      startDate: this.startDate,
      endDate: this.endDate,
      groupId: this.mcGroupId,
      shiftTypeId: filters.shiftName ? filters.shiftName.value : null,
      status: filters.statusLabel ? filters.statusLabel.value.trim() : null,
      incoming: filters.incomingName ? filters.incomingName.value.trim() : null,
      departing: filters.departingName
        ? filters.departingName.value.trim()
        : null,
    };
    this._shiftTurnoverTableService.setLastQuery(query);
    this.queryNetwork(req, $event, query);
  }

  private queryNetwork(req, $event, query) {
    req.page += 1;
    const sort = {};
    if ($event.lazy && $event.lazy.sortField) {
      let field = $event.lazy.sortField;
      if ($event.lazy.sortField === 'incomingName') {
        field = 'incoming';
      } else if ($event.lazy.sortField === 'departingName') {
        field = 'departing';
      } else if ($event.lazy.sortField === 'statusLabel') {
        field = 'status';
      }
      sort[field] =
        $event.lazy.sortOrder && $event.lazy.sortOrder === 1 ? 'DESC' : 'ASC';
      this._shiftTurnoverTableService.setLastSortField($event.lazy.sortField);
      this._shiftTurnoverTableService.setLastSortDirection(
        $event.lazy.sortOrder
      );
    } else {
      sort['shiftDate'] = 'DESC';
      this._shiftTurnoverTableService.setLastSortField('shiftDate');
      this._shiftTurnoverTableService.setLastSortDirection(0);
    }
    if (!this._isRefreshing) {
      this._isRefreshing = true;
      this._shiftTurnoverApi
        .queryForShiftTurnovers(req.pageSize, req.page, query, sort)
        .pipe(take(1))
        .subscribe(
          ({ data }) => {
            const clone = Object.assign({}, data);
            const shifts = clone.queryForShiftTurnovers.items.map((i) =>
              this._shiftTurnoverBuilder.buildShiftTurnoverSearchResult(i)
            );
            this.totalRecords = clone.queryForShiftTurnovers.totalRecords;
            this.elements = [...shifts];
            this.loading = false;
            this._isRefreshing = false;
          },
          (error) => {
            console.log(error);
            this.loading = false;
            this._isRefreshing = false;
          }
        );
    }
  }

  newShiftChange() {
    const array = this.groups.filter((g) => g.name === 'Monitoring Center');
    if (array.length > 0) {
      const group = array[0];
      const st = this._shiftTurnoverBuilder.buildNullShiftTurnover(group.id);
      this._shiftTurnoverTableService.setSelected(st);
      const tab = TabService.getInstance().buildNewTab(
        MC_ST_DETAIL_CONTAINER,
        true
      );
      TabService.getInstance().openTab(tab);
    }
  }

  refresh(setLoading = true): void {
    this.lazyLoad(
      { lazy: this._shiftTurnoverTableService.getLastLazyLoad() },
      setLoading
    );
  }
}
