<div id="monlogs-details-container">
  <app-progress-spinner [enabled]="!form"></app-progress-spinner>
  <p-blockUI [blocked]="saving === true || loading === true"></p-blockUI>
  <form [formGroup]="form" id="monlog-create-form" *ngIf="form">
    <div
      *ngIf="ifDeviceMatches([DeviceSize.MD, DeviceSize.LG, DeviceSize.XL])"
      class="xtra-small-top-padding"
    >
      <div id="contact-action-buttons">
        <div class="no-padding-top-bottom">
          <div class="action-items" *ngIf="!monLog || !monLog.id">
            <div class="item-long">
              <div>
                <app-progress-spinner [enabled]="saving"></app-progress-spinner>
              </div>
              <div
                class="button-wrapper no-padding-top-bottom"
                *ngIf="isEditting === false && canEdit === true"
              >
                <button
                  pButton
                  label="{{ 'COMMON.LABEL.BUTTONS.EDIT' | translate }}"
                  icon="fa fa-fw fa-edit"
                  (click)="setEditting()"
                ></button>
              </div>
              <div
                class="button-wrapper no-padding-top-bottom item-long"
                *ngIf="
                  isEditting === true &&
                  (canEdit === true || canCreate === true)
                "
              >
                <button
                  pButton
                  label="{{ 'COMMON.LABEL.BUTTONS.SAVE' | translate }}"
                  icon="fa fa-fw fa-save"
                  [disabled]="!this.form.valid || this.saving"
                  (click)="saveMonitoringLog()"
                ></button>
              </div>
              <div class="button-wrapper">
                <button
                  pButton
                  class="refresh-button"
                  icon="fa fa-fw fa-refresh"
                  *ngIf="monLog.id !== null"
                  (click)="refresh()"
                ></button>
              </div>
            </div>
          </div>

          <div class="columns">
            <div class="no-padding-top-bottom column" *ngIf="monLog.id">
              <div class="column-item">
                <span class="label no-padding-top-bottom"
                  >{{ "MONLOG.LABEL.CREATED_BY" | translate }}:</span
                >
                <span class="free-text-value no-padding-top-bottom"
                  >{{ monLog.createdBy }}
                  on
                  {{ monLog.createdAt | date : "MM/dd/yyyy hh:mm:ss a" }}</span
                >
              </div>

              <div class="column-item">
                <span class="label no-padding-top-bottom"
                  >{{ "MONLOG.LABEL.LAST_UPDATED" | translate }}:</span
                >
                <span class="free-text-value no-padding-top-bottom">{{
                  monLog.updatedAt | date : "MM/dd/yyyy hh:mm:ss a"
                }}</span>
              </div>

              <div class="column-item">
                <span class="label no-padding-top-bottom; margin-right: 8px"
                  >{{ "MONLOG.LABEL.CURRENT_STATUS" | translate }}:</span
                >
                <span
                  class="free-text-value no-padding-top-bottom"
                  *ngIf="monLog.logStatus === 'O'"
                  >{{ "MONLOG.LABEL.OPEN" | translate }}</span
                >
                <span
                  class="free-text-value no-padding-top-bottom"
                  *ngIf="monLog.logStatus === 'A'"
                  >{{ "MONLOG.LABEL.AWAITING_REVIEW" | translate }}</span
                >
                <span
                  class="free-text-value no-padding-top-bottom"
                  *ngIf="monLog.logStatus === 'C'"
                  >{{ "MONLOG.LABEL.REVIEW_COMPLETE" | translate }}</span
                >
                <span
                  class="free-text-value no-padding-top-bottom"
                  *ngIf="monLog.logStatus === 'R'"
                  >{{ "MONLOG.LABEL.RESOLVED" | translate }}</span
                >
              </div>

              <div class="column-item" *ngIf="monLog.reviewRequestedDt">
                <span class="label no-padding-top-bottom"
                  >{{ "MONLOG.LABEL.REVIEW_REQUESTED" | translate }}:</span
                >
                <span
                  class="free-text-value no-padding-top-bottom"
                  *ngIf="monLog.reviewRequestedDt"
                  >{{
                    monLog.reviewRequestedDt | date : "yyyy-MM-dd HH:mm:ss"
                  }}</span
                >
              </div>

              <div class="column-item">
                <span
                  class="label no-padding-top-bottom"
                  *ngIf="monLog.reviewCompletedDt"
                >
                  {{ "MONLOG.LABEL.REVIEW_COMPLETED" | translate }}:</span
                >
                <span
                  class="free-text-value no-padding-top-bottom"
                  *ngIf="monLog.reviewCompletedDt"
                >
                  {{
                    monLog.reviewCompletedDt | date : "yyyy-MM-dd HH:mm:ss"
                  }}</span
                >
                <span
                  class="label no-padding-top-bottom reviewer"
                  *ngIf="monLog.reviewer"
                >
                  {{ "MONLOG.LABEL.REVIEWER" | translate }}:</span
                >
                <span
                  class="free-text-value no-padding-top-bottom"
                  *ngIf="monLog.reviewer"
                >
                  {{ monLog.reviewer }}</span
                >
              </div>
            </div>
            <div class="no-padding-top-bottom column" *ngIf="monLog.id">
              <div
                class="button-wrapper no-padding-top-bottom row-item"
                *ngIf="monLog?.id"
              >
                <span
                  style="
                    font-weight: 700;
                    text-align: right;
                    padding-right: 0.25em;
                  "
                  >{{ "COMMON.LABEL.ACTIONS" | translate }}:</span
                >
                <span
                  class="no-padding-top-bottom"
                  style="text-align: left !important"
                >
                  <p-dropdown
                    #dd
                    [options]="actions"
                    [(ngModel)]="selectedAction"
                    [ngModelOptions]="{ standalone: true }"
                    (onChange)="actionEvent($event, dd)"
                    styleclass="action-drop-down"
                  ></p-dropdown>
                </span>

                <div
                  class="no-padding-top-bottom display-flex"
                  style="text-align: right; margin-left: 8px"
                >
                  <div>
                    <app-progress-spinner
                      [enabled]="saving"
                    ></app-progress-spinner>
                  </div>
                  <div
                    class="button-wrapper no-padding-top-bottom"
                    *ngIf="isEditting === false && canEdit === true"
                  >
                    <button
                      pButton
                      label="{{ 'COMMON.LABEL.BUTTONS.EDIT' | translate }}"
                      icon="fa fa-fw fa-edit"
                      (click)="setEditting()"
                    ></button>
                  </div>
                  <div
                    class="button-wrapper no-padding-top-bottom"
                    *ngIf="isEditting === true && canEdit === true"
                  >
                    <button
                      pButton
                      label="{{ 'COMMON.LABEL.BUTTONS.SAVE' | translate }}"
                      icon="fa fa-fw fa-save"
                      [disabled]="!this.form.valid || this.saving"
                      (click)="saveMonitoringLog()"
                    ></button>
                  </div>
                  <div class="button-wrapper">
                    <button
                      pButton
                      class="refresh-button"
                      icon="fa fa-fw fa-refresh"
                      *ngIf="monLog.id !== null"
                      (click)="refresh()"
                    ></button>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="columns">
            <div class="status-summary column-long">
              <div class="column-item">
                <span class="label"
                  >{{ "MONLOG.LABEL.STATUS_SUMMARY" | translate }}:</span
                >
                <span class="input">
                  <input pInputText formControlName="statusSummary" />
                  <app-inline-validation
                    class="input-field"
                    field="statusSummary"
                    [control]="getBaseFormChild('statusSummary')"
                    [validationMessages]="validationMessages"
                    [validationParams]="validationParams"
                  >
                  </app-inline-validation>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <p-tabView [activeIndex]="tabIndex" (onChange)="indexChanged($event)">
        <p-tabPanel header="{{ 'MONLOG.LABEL.CALL_INFORMATION' | translate }}">
          <p-panel
            header="{{ 'MONLOG.LABEL.SECTION_ONE' | translate }}"
            styleclass="mon-log-panel"
          >
            <p-panel
              [showHeader]="false"
              class="flex"
              styleclass="mon-log-panel"
            >
              <div class="form-section">
                <div
                  class="section-element"
                  [ngClass]="{
                    long:
                      monLog.logCategory !== 'MR' && monLog.logCategory !== 'AE'
                  }"
                >
                  <span class="label"
                    ><span class="asterisk-call-type">*</span
                    >{{ "MONLOG.LABEL.TYPE_OF_CALL" | translate }}:</span
                  >
                  <span class="row-input">
                    <p-multiSelect
                      class="input"
                      [options]="types"
                      [maxSelectedLabels]="10"
                      (onChange)="callTypeChange($event)"
                      formControlName="typeOfCalls"
                      styleclass="multiselect-resize"
                    >
                    </p-multiSelect>
                    <app-inline-validation
                      field="typeOfCalls"
                      class="validation"
                      [control]="getBaseFormChild('typeOfCalls')"
                      [validationMessages]="validationMessages"
                      [validationParams]="validationParams"
                    >
                    </app-inline-validation>
                  </span>
                </div>

                <div
                  class="short section-element"
                  *ngIf="
                    monLog.logCategory !== 'MR' && monLog.logCategory !== 'AE'
                  "
                >
                  <span class="label"
                    >{{ "MONLOG.LABEL.CUSTOMER_OUTAGE" | translate }}:</span
                  >
                  <span class="row-input radio">
                    <div>
                      <p-radioButton
                        name="customerOutage"
                        [value]="true"
                        label="{{ 'COMMON.LABEL.BUTTONS.YES' | translate }}"
                        formControlName="customerOutage"
                      >
                      </p-radioButton>
                    </div>
                    <div>
                      <p-radioButton
                        name="customerOutage"
                        [value]="false"
                        label="{{ 'COMMON.LABEL.BUTTONS.NO' | translate }}"
                        formControlName="customerOutage"
                      >
                      </p-radioButton>
                    </div>
                  </span>
                </div>
                <div
                  class="short section-element"
                  *ngIf="
                    monLog.logCategory !== 'MR' && monLog.logCategory !== 'AE'
                  "
                >
                  <span class="label"
                    >{{ "MONLOG.LABEL.CALLOUT_REQUIRED" | translate }}:</span
                  >
                  <span class="row-input radio">
                    <div>
                      <p-radioButton
                        name="calloutRequired"
                        [value]="true"
                        label="{{ 'COMMON.LABEL.BUTTONS.YES' | translate }}"
                        formControlName="calloutRequired"
                      >
                      </p-radioButton>
                    </div>
                    <div>
                      <p-radioButton
                        name="calloutRequired"
                        [value]="false"
                        label="{{ 'COMMON.LABEL.BUTTONS.NO' | translate }}"
                        formControlName="calloutRequired"
                      >
                      </p-radioButton>
                    </div>
                    <app-inline-validation
                      field="calloutRequired"
                      [control]="getBaseFormChild('calloutRequired')"
                      [validationMessages]="validationMessages"
                      [validationParams]="validationParams"
                    >
                    </app-inline-validation>
                  </span>
                </div>
                <div
                  class="short section-element"
                  *ngIf="
                    monLog.logCategory !== 'MR' && monLog.logCategory !== 'AE'
                  "
                >
                  <span class="label"
                    >{{ "MONLOG.LABEL.FALSE_ALARM" | translate }}:</span
                  >
                  <span class="row-input radio">
                    <div>
                      <p-radioButton
                        name="falseAlarm"
                        [value]="true"
                        label="{{ 'COMMON.LABEL.BUTTONS.YES' | translate }}"
                        formControlName="falseAlarm"
                      >
                      </p-radioButton>
                    </div>
                    <div>
                      <p-radioButton
                        name="falseAlarm"
                        [value]="false"
                        label="{{ 'COMMON.LABEL.BUTTONS.NO' | translate }}"
                        formControlName="falseAlarm"
                      >
                      </p-radioButton>
                    </div>
                  </span>
                </div>
                <div
                  class="section-element"
                  *ngIf="
                    monLog.logCategory !== 'MR' && monLog.logCategory !== 'AE'
                  "
                >
                  <span class="label"
                    >{{ "MONLOG.LABEL.TIME_BEGAN" | translate }}:</span
                  >
                  <span class="row-input">
                    <div class="ui-inputgroup">
                      <p-calendar
                        class="input"
                        [timeOnly]="true"
                        [showSeconds]="true"
                        hourFormat="12"
                        formControlName="timeBegan"
                      >
                      </p-calendar>
                      <span class="addon"
                        ><i
                          class="fa fa-clock-o clock-icon"
                          (click)="setTimeBegan()"
                        ></i
                      ></span>
                    </div>
                  </span>
                </div>
                <div
                  class="section-element"
                  *ngIf="
                    monLog.logCategory !== 'MR' && monLog.logCategory !== 'AE'
                  "
                >
                  <span class="label"
                    >{{ "MONLOG.LABEL.TIME_ON_SITE" | translate }}:</span
                  >
                  <span class="row-input">
                    <div class="ui-inputgroup">
                      <p-calendar
                        class="input"
                        [timeOnly]="true"
                        hourFormat="12"
                        formControlName="timeOnSite"
                        [showSeconds]="true"
                      >
                      </p-calendar>
                      <span class="addon"
                        ><i
                          class="fa fa-clock-o clock-icon"
                          (click)="setTimeOnSite()"
                        ></i
                      ></span>
                    </div>
                  </span>
                </div>
                <div
                  class="section-element"
                  *ngIf="
                    monLog.logCategory !== 'MR' && monLog.logCategory !== 'AE'
                  "
                >
                  <span class="label"
                    >{{ "MONLOG.LABEL.ONE_CALL_NO" | translate }}:</span
                  >
                  <span class="row-input">
                    <input
                      class="input"
                      pInputText
                      formControlName="oneCallNumber"
                    />
                  </span>
                </div>
                <div
                  class="section-element"
                  *ngIf="
                    monLog.logCategory !== 'MR' && monLog.logCategory !== 'AE'
                  "
                >
                  <span class="label"
                    >{{ "MONLOG.LABEL.TICKET_NUMBER" | translate }}:</span
                  >
                  <span class="row-input">
                    <input
                      class="input"
                      pInputText
                      formControlName="ticketNumber"
                    />
                  </span>
                </div>

                <div
                  class="section-element"
                  *ngIf="showAEWorkTypes === true"
                  [ngClass]="{
                    'long-ae-mr':
                      monLog.logCategory === 'MR' || monLog.logCategory === 'AE'
                  }"
                >
                  <span class="label"
                    ><span class="asterisk">*</span
                    >{{ "MONLOG.LABEL.WORK_TYPE" | translate }}:</span
                  >
                  <span class="row-input">
                    <p-multiSelect
                      class="input"
                      [options]="aeWorkTypes"
                      [maxSelectedLabels]="10"
                      formControlName="workTypes"
                    >
                    </p-multiSelect>
                    <app-inline-validation
                      field="workType"
                      [control]="getBaseFormChild('workTypes')"
                      [validationMessages]="validationMessages"
                      [validationParams]="validationParams"
                    >
                    </app-inline-validation>
                  </span>
                </div>

                <div
                  class="section-element long-ae-mr"
                  *ngIf="
                    showMRWorkTypes === true &&
                    (monLog.logCategory === 'MR' || monLog.logCategory === 'AE')
                  "
                >
                  <span class="label"
                    ><span class="asterisk">*</span
                    >{{ "MONLOG.LABEL.WORK_TYPE" | translate }}:</span
                  >
                  <span class="row-input">
                    <p-multiSelect
                      class="input"
                      [options]="mrWorkTypes"
                      [maxSelectedLabels]="10"
                      formControlName="workTypes"
                    >
                    </p-multiSelect>
                    <app-inline-validation
                      field="workType"
                      [control]="getBaseFormChild('workTypes')"
                      [validationMessages]="validationMessages"
                      [validationParams]="validationParams"
                    >
                    </app-inline-validation>
                  </span>
                </div>

                <div
                  class="section-element"
                  *ngIf="
                    showGasReleaseType === true &&
                    monLog.logCategory !== 'MR' &&
                    monLog.logCategory !== 'AE'
                  "
                >
                  <span class="label"
                    ><span class="asterisk">*</span
                    >{{ "MONLOG.LABEL.GAS_RELEASE_TYPE" | translate }}:</span
                  >
                  <span class="row-input">
                    <p-dropdown
                      class="input"
                      [options]="gasReleaseTypes"
                      formControlName="gasReleaseType"
                    ></p-dropdown>
                    <app-inline-validation
                      field="gasReleaseType"
                      [control]="getBaseFormChild('gasReleaseType')"
                      [validationMessages]="validationMessages"
                      [validationParams]="validationParams"
                    >
                    </app-inline-validation>
                  </span>
                </div>
                <div
                  class="section-element"
                  *ngIf="
                    showSeverity === true &&
                    monLog.logCategory !== 'MR' &&
                    monLog.logCategory !== 'AE'
                  "
                >
                  <span class="label"
                    >{{ "MONLOG.LABEL.SEVERITY" | translate }}:</span
                  >
                  <span class="row-input">
                    <p-dropdown
                      class="input"
                      [options]="commFailSeverities"
                      formControlName="commFailSeverity"
                    >
                    </p-dropdown>
                    <app-inline-validation
                      field="commFailSeverity"
                      [control]="getBaseFormChild('commFailSeverity')"
                      [validationMessages]="validationMessages"
                      [validationParams]="validationParams"
                    >
                    </app-inline-validation>
                  </span>
                </div>
                <div
                  class="section-element"
                  *ngIf="
                    showNotam === true &&
                    monLog.logCategory !== 'MR' &&
                    monLog.logCategory !== 'AE'
                  "
                >
                  <span class="label"
                    >{{ "MONLOG.LABEL.NOTAM" | translate }}:</span
                  >
                  <span class="row-input">
                    <input class="input" pInputText formControlName="notam" />
                  </span>
                </div>

                <div class="row">
                  <div
                    class="row-item-short no-padding-top-bottom"
                    *ngIf="
                      showNotam === true &&
                      monLog.logCategory !== 'MR' &&
                      monLog.logCategory !== 'AE'
                    "
                  >
                    <span class="label"
                      >{{ "MONLOG.LABEL.EXPIRE_DT" | translate }}:</span
                    >
                    <span class="row-input">
                      <p-calendar
                        class="input"
                        formControlName="expireDt"
                      ></p-calendar>
                    </span>
                  </div>
                </div>
              </div>
            </p-panel>

            <p-panel
              header="{{ 'MONLOG.LABEL.CALLER_LOCATION_INFO' | translate }}"
              styleclass="mon-log-panel"
              [toggleable]="true"
            >
              <div
                class="form-section"
                [style.--columns]="
                  monLog.logCategory === 'MR' || monLog.logCategory === 'AE'
                    ? '3'
                    : '7'
                "
              >
                <div class="section-element" *ngIf="monLog.logCategory === 'N'">
                  <span class="label"
                    >{{ "CONTACT.LABEL.FIRST_NAME" | translate }}:</span
                  >
                  <span class="row-input">
                    <input
                      class="input"
                      pInputText
                      formControlName="firstName"
                    />
                  </span>
                </div>
                <div class="section-element" *ngIf="monLog.logCategory === 'N'">
                  <span class="label"
                    >{{ "CONTACT.LABEL.LAST_NAME" | translate }}:</span
                  >
                  <span class="row-input">
                    <input
                      class="input"
                      pInputText
                      formControlName="lastName"
                    />
                  </span>
                </div>
                <div class="section-element" *ngIf="monLog.logCategory === 'N'">
                  <span class="label"
                    >{{ "MONLOG.LABEL.PHONE" | translate }}:</span
                  >
                  <span class="row-input">
                    <p-inputMask
                      class="input"
                      mask="999-999-9999"
                      id="businessPhone"
                      formControlName="phone"
                      (onBlur)="fixMask('phone')"
                    >
                    </p-inputMask>
                  </span>
                </div>
                <div class="section-element" *ngIf="monLog.logCategory === 'N'">
                  <span class="label"
                    >{{ "MONLOG.LABEL.STREET" | translate }}:</span
                  >
                  <span class="row-input">
                    <input class="input" pInputText formControlName="street" />
                  </span>
                </div>

                <div
                  *ngIf="
                    monLog.logCategory === 'I' ||
                    monLog.logCategory === 'MR' ||
                    monLog.logCategory === 'AE'
                  "
                  class="section-element"
                >
                  <span class="label"
                    >{{
                      "MONLOG.LABEL.INTERNAL_CONTACT_ONE" | translate
                    }}:</span
                  >
                  <span
                    class="input-icon text-right"
                    style="margin-right: 8px"
                    *ngIf="
                      canEdit === true &&
                      (monLog.logCategory === 'MR' ||
                        monLog.logCategory === 'AE')
                    "
                  >
                    <i
                      class="fa fa-fw fa-phone-square"
                      (click)="addControllerEntry(0)"
                    ></i>
                  </span>
                  <span class="internal">
                    <p-autoComplete
                      class="input internal"
                      [suggestions]="filteredContacts"
                      formControlName="internalOne"
                      [dropdown]="true"
                      optionLabel="fullName"
                      (completeMethod)="contactSearch($event, true)"
                      appendTo="body"
                      [forceSelection]="true"
                      [virtualScroll]="true"
                      [virtualScrollItemSize]="34"
                    >
                    </p-autoComplete>
                  </span>
                </div>
                <div
                  *ngIf="
                    monLog.logCategory === 'I' ||
                    monLog.logCategory === 'MR' ||
                    monLog.logCategory === 'AE'
                  "
                  class="section-element"
                >
                  <span class="label"
                    >{{
                      "MONLOG.LABEL.INTERNAL_CONTACT_TWO" | translate
                    }}:</span
                  >
                  <span
                    class="input-icon text-right"
                    style="margin-right: 8px"
                    *ngIf="
                      canEdit === true &&
                      (monLog.logCategory === 'MR' ||
                        monLog.logCategory === 'AE')
                    "
                  >
                    <i
                      class="fa fa-fw fa-phone-square"
                      (click)="addControllerEntry(1)"
                    ></i>
                  </span>
                  <span class="internal">
                    <p-autoComplete
                      class="input internal"
                      [suggestions]="filteredContacts"
                      formControlName="internalTwo"
                      [dropdown]="true"
                      optionLabel="fullName"
                      (completeMethod)="contactSearch($event, true)"
                      appendTo="body"
                      [forceSelection]="true"
                      [virtualScroll]="true"
                      [virtualScrollItemSize]="34"
                    >
                    </p-autoComplete>
                  </span>
                </div>
              </div>

              <div
                class="form-section"
                *ngIf="
                  monLog.logCategory !== 'MR' && monLog.logCategory !== 'AE'
                "
              >
                <div class="section-element">
                  <span class="label"
                    >{{ "LOCATION.LABEL.STATE" | translate }}:</span
                  >
                  <span class="row-input">
                    <p-autoComplete
                      class="input"
                      [suggestions]="filteredStates"
                      (completeMethod)="stateSearch($event)"
                      (onSelect)="selectState($event, this.form)"
                      formControlName="state"
                      [dropdown]="true"
                      appendTo="body"
                      [virtualScroll]="true"
                      [virtualScrollItemSize]="34"
                    >
                    </p-autoComplete>
                  </span>
                </div>
                <div class="section-element">
                  <span class="label"
                    >{{ "LOCATION.LABEL.COUNTY" | translate }}:</span
                  >
                  <span class="row-input">
                    <p-autoComplete
                      class="input"
                      [suggestions]="filteredCounties"
                      (completeMethod)="countySearch($event, this.form)"
                      formControlName="county"
                      [dropdown]="true"
                      optionLabel="county"
                      (onSelect)="selectCounty($event, this.form)"
                      appendTo="body"
                      [virtualScroll]="true"
                      [virtualScrollItemSize]="34"
                    >
                    </p-autoComplete>
                  </span>
                </div>
                <div class="section-element">
                  <span class="label"
                    >{{ "LOCATION.LABEL.TOWNSHIP" | translate }}:</span
                  >
                  <span class="row-input">
                    <p-autoComplete
                      class="input"
                      [suggestions]="filteredTownships"
                      (completeMethod)="townshipSearch($event, this.form)"
                      formControlName="township"
                      [dropdown]="true"
                      optionLabel="township"
                      (onSelect)="selectTownship($event, this.form)"
                      appendTo="body"
                      [virtualScroll]="true"
                      [virtualScrollItemSize]="34"
                    >
                    </p-autoComplete>
                  </span>
                </div>

                <div class="section-element">
                  <span class="label"
                    >{{ "LOCATION.LABEL.CITY" | translate }}:</span
                  >
                  <span class="row-input">
                    <input class="input" pInputText formControlName="city" />
                  </span>
                </div>
              </div>
            </p-panel>

            <p-panel
              header="{{ 'MONLOG.LABEL.FACILITY_INFO' | translate }}"
              styleclass="mon-log-panel row"
              [toggleable]="true"
            >
              <div class="row">
                <div
                  class="form-section"
                  style="--columns: 8"
                  formGroupName="Facility"
                  [ngClass]="{
                    'small-grid': ['I', 'O', 'N', 'MR', 'AE'].includes(
                      monLog.logCategory
                    )
                  }"
                >
                  <div
                    class="section-element"
                    [ngClass]="{
                      long: ['I', 'O', 'N', 'MR', 'AE'].includes(
                        monLog.logCategory
                      )
                    }"
                  >
                    <span
                      class="label"
                      [ngClass]="{ 'long-label': isEditting }"
                    >
                      <span style="margin-right: 8px"
                        ><button
                          pButton
                          icon="fa fa-fw fa-eraser"
                          *ngIf="isEditting === true"
                          pTooltip="Clear Facility and Location"
                          (click)="resetFacility()"
                        ></button> </span
                      >{{ "LOCATION.LABEL.FACILITY_TYPE" | translate }}:
                    </span>
                    <span class="row-input">
                      <p-autoComplete
                        class="input"
                        [suggestions]="filteredFacilityTypes"
                        (completeMethod)="facilityTypeSearch($event)"
                        formControlName="facilityType"
                        [dropdown]="true"
                        (onSelect)="facilityTypeSelected($event)"
                        appendTo="body"
                        [virtualScroll]="true"
                        [virtualScrollItemSize]="34"
                        *ngIf="!facilitiesLoading"
                      >
                      </p-autoComplete>
                      <p-progressSpinner
                        *ngIf="facilitiesLoading"
                        [style]="{ width: '50px', height: '50px' }"
                      ></p-progressSpinner>
                    </span>
                  </div>
                  <div class="section-element long facility-name">
                    <span class="label"
                      >{{ "LOCATION.LABEL.FACILITY_NAME" | translate }}:</span
                    >
                    <span class="row-input">
                      <p-autoComplete
                        class="input"
                        [suggestions]="filteredFacilityNames"
                        (completeMethod)="facilityNameSearch($event)"
                        formControlName="facilityName"
                        [dropdown]="true"
                        optionLabel="facilityName"
                        (onSelect)="facilityNameSelected($event)"
                        appendTo="body"
                        [virtualScroll]="true"
                        [virtualScrollItemSize]="34"
                        *ngIf="!facilitiesLoading"
                      >
                      </p-autoComplete>
                      <p-progressSpinner
                        *ngIf="facilitiesLoading"
                        [style]="{ width: '50px', height: '50px' }"
                      ></p-progressSpinner>
                    </span>
                  </div>
                  <div class="section-element facility-id">
                    <span class="label"
                      >{{ "LOCATION.LABEL.FACILITY_UID" | translate }}:</span
                    >
                    <span class="row-input">
                      <p-autoComplete
                        class="input"
                        [suggestions]="filteredFacilityIds"
                        (completeMethod)="facilityIdSearch($event)"
                        formControlName="facilityUid"
                        [dropdown]="true"
                        optionLabel="facilityUid"
                        (onSelect)="facilityIdSelected($event)"
                        appendTo="body"
                        [virtualScroll]="true"
                        [virtualScrollItemSize]="34"
                        *ngIf="!facilitiesLoading"
                      >
                      </p-autoComplete>
                      <p-progressSpinner
                        *ngIf="facilitiesLoading"
                        [style]="{ width: '50px', height: '50px' }"
                      ></p-progressSpinner>
                    </span>
                  </div>
                  <div
                    class="section-element"
                    *ngIf="
                      monLog.logCategory !== 'MR' && monLog.logCategory !== 'AE'
                    "
                  >
                    <span class="label"
                      >{{ "MONLOG.LABEL.PIPELINE" | translate }}:</span
                    >
                    <span class="row-input">
                      <input
                        class="input"
                        pInputText
                        formControlName="pipeline"
                      />
                    </span>
                  </div>
                  <div class="section-element">
                    <span class="label"
                      >{{ "LOCATION.LABEL.AOR" | translate }}:</span
                    >
                    <span class="row-input">
                      <input class="input" pInputText formControlName="aor" />
                    </span>
                  </div>
                  <div class="section-element">
                    <span class="label"
                      ><span class="asterisk-business-unit">*</span> Business
                      Unit:</span
                    >
                    <span class="row-input">
                      <p-autoComplete
                        class="input"
                        [suggestions]="filteredBusinessUnits"
                        (completeMethod)="businessUnitSearch($event)"
                        formControlName="businessUnit"
                        [dropdown]="true"
                        optionLabel="name"
                        appendTo="body"
                        [virtualScroll]="true"
                        [virtualScrollItemSize]="34"
                        *ngIf="!facilitiesLoading"
                      >
                      </p-autoComplete>
                    </span>
                  </div>
                </div>
              </div>
            </p-panel>

            <p-panel
              header="{{ 'MONLOG.LABEL.PRIMARY_CONTACT_INFO' | translate }}"
              styleclass="mon-log-panel"
              [toggleable]="true"
            >
              <div class="panel edit">
                <div
                  class="panel no-padding-top-bottom small-panel"
                  formArrayName="MonLogContacts"
                  *ngFor="
                    let contact of form.get('MonLogContacts')['controls'];
                    let i = index
                  "
                >
                  <div
                    [formGroupName]="i"
                    class="form-section"
                    style="--columns: 3"
                  >
                    <div
                      [ngClass]="{
                        'section-element': !isEditting,
                        'edit-element': isEditting
                      }"
                    >
                      <div
                        class="label"
                        [ngClass]="{ 'long-label': isEditting }"
                      >
                        <span
                          ><button
                            pButton
                            icon="fa fa-trash"
                            pTooltip="Remove Contact Row"
                            *ngIf="i !== 0 && isEditting === true"
                            (click)="removeContactRow(i)"
                          ></button
                        ></span>
                        <span
                          style="font-size: 12px"
                          [ngClass]="{ trash: isEditting }"
                          *ngIf="i === 0"
                          [translate]="'MONLOG.LABEL.PRIMARY_CONTACT'"
                        ></span>
                        <span
                          style="font-size: 12px; margin-left: 0.2vw"
                          [ngClass]="{ trash: isEditting }"
                          *ngIf="i !== 0"
                          [translate]="'MONLOG.LABEL.OTHER_CONTACT'"
                          [translateParams]="{ index: i }"
                        ></span>
                        <span
                          class="input-icon text-right"
                          *ngIf="
                            canEdit === true &&
                            this.monLog.id &&
                            contact['controls'].primary.value
                              ?.isPrimaryDispatched === false
                          "
                        >
                          <i
                            class="fa fa-fw fa-phone-square"
                            (click)="
                              dispatchContact(
                                contact['controls'].primary.value?.primaryId
                              )
                            "
                          ></i>
                        </span>
                        <span
                          class="input-icon text-right"
                          *ngIf="
                            canEdit === true &&
                            this.monLog.id &&
                            contact['controls'].primary.value
                              ?.isPrimaryDispatched === true
                          "
                        >
                          <i
                            class="fa fa-fw fa-home"
                            (click)="
                              returnContact(
                                contact['controls'].primary.value?.primaryId
                              )
                            "
                          ></i>
                        </span>
                        <span
                          class="input-icon text-right"
                          *ngIf="contact['controls'].primary.value === null"
                        >
                        </span>
                      </div>
                      <span class="input-icon" style="width: 32px">
                        <i
                          class="fa fa-fw fa-id-card"
                          (click)="
                            goToContact(
                              contact['controls'].primary.value.primaryId
                            )
                          "
                        ></i>
                      </span>
                      <span class="row-input-contact">
                        <p-autoComplete
                          class="ml-contact-input"
                          [suggestions]="filteredContacts"
                          formControlName="primary"
                          [dropdown]="true"
                          optionLabel="primaryFullName"
                          (onSelect)="contactSelected(i, $event)"
                          (completeMethod)="contactSearch($event)"
                          appendTo="body"
                          [forceSelection]="true"
                          [virtualScroll]="true"
                          [virtualScrollItemSize]="34"
                        >
                        </p-autoComplete>
                      </span>
                      <div class="section-element">
                        <div class="label business-mobile">
                          <span
                            [translate]="'MONLOG.LABEL.BUSINESS_MOBILE'"
                          ></span>
                        </div>
                        <a
                          href="tel:9-1-{{
                            contact['controls'].primary.value
                              ?.primaryBusinessMobile
                          }}"
                          >{{
                            contact["controls"].primary.value
                              ?.primaryBusinessMobile
                          }}</a
                        >
                      </div>
                    </div>

                    <div
                      [ngClass]="{
                        'section-element': !isEditting,
                        'edit-element': isEditting
                      }"
                    >
                      <div
                        class="label"
                        [ngClass]="{ 'long-label': isEditting }"
                      >
                        <span
                          style="font-size: 12px"
                          *ngIf="i === 0"
                          [translate]="'MONLOG.LABEL.SUPERVISOR'"
                        ></span>
                        <span
                          style="font-size: 12px"
                          *ngIf="i !== 0"
                          [translate]="'MONLOG.LABEL.OTHER_SUPERVISOR'"
                          [translateParams]="{ index: i }"
                        ></span>
                        <span
                          class="input-icon text-right"
                          *ngIf="
                            canEdit === true &&
                            this.monLog.id &&
                            contact['controls'].primary.value
                              ?.isSupervisorDispatched === false
                          "
                        >
                          <i
                            class="fa fa-fw fa-phone-square"
                            (click)="
                              dispatchContact(
                                contact['controls'].primary.value?.supervisorId
                              )
                            "
                          ></i>
                        </span>
                        <span
                          class="input-icon text-right"
                          *ngIf="
                            canEdit === true &&
                            this.monLog.id &&
                            contact['controls'].primary.value
                              ?.isSupervisorDispatched === true
                          "
                        >
                          <i
                            class="fa fa-fw fa-home"
                            (click)="
                              returnContact(
                                contact['controls'].primary.value?.supervisorId
                              )
                            "
                          ></i>
                        </span>
                        <span
                          class="input-icon text-right"
                          *ngIf="contact['controls'].primary.value === null"
                        >
                        </span>
                      </div>
                      <span class="input-icon" style="width: 32px">
                        <i
                          class="fa fa-fw fa-id-card"
                          (click)="
                            goToContact(
                              contact['controls'].primary.value.supervisorId
                            )
                          "
                        ></i>
                      </span>
                      <span class="row-input-contact">
                        <p-autoComplete
                          class="ml-contact-input"
                          formControlName="supervisor"
                          [dropdown]="true"
                          optionLabel="supervisorFullName"
                          appendTo="body"
                          [virtualScroll]="true"
                          [virtualScrollItemSize]="34"
                        >
                        </p-autoComplete>
                      </span>
                      <div class="section-element">
                        <div class="label business-mobile">
                          <span
                            [translate]="'MONLOG.LABEL.BUSINESS_MOBILE'"
                          ></span>
                        </div>
                        <a
                          href="tel:9-1-{{
                            contact['controls'].primary.value
                              ?.supervisorBusinessMobile
                          }}"
                          >{{
                            contact["controls"].primary.value
                              ?.supervisorBusinessMobile
                          }}</a
                        >
                      </div>
                    </div>

                    <div
                      [ngClass]="{
                        'section-element': !isEditting,
                        'edit-element': isEditting
                      }"
                    >
                      <div
                        class="label"
                        [ngClass]="{ 'long-label': isEditting }"
                      >
                        <span
                          *ngIf="i === 0"
                          [translate]="'MONLOG.LABEL.MANAGER'"
                          style="font-size: 12px"
                        ></span>
                        <span
                          style="font-size: 12px"
                          *ngIf="i !== 0"
                          [translate]="'MONLOG.LABEL.OTHER_MANAGER'"
                          [translateParams]="{ index: i }"
                        ></span>
                        <span
                          class="input-icon text-right"
                          *ngIf="
                            canEdit === true &&
                            this.monLog.id &&
                            contact['controls'].primary.value
                              ?.isManagerDispatched === false
                          "
                        >
                          <i
                            class="fa fa-fw fa-phone-square"
                            (click)="
                              dispatchContact(
                                contact['controls'].primary.value.managerId
                              )
                            "
                          ></i>
                        </span>
                        <span
                          class="input-icon text-right"
                          *ngIf="
                            canEdit === true &&
                            this.monLog.id &&
                            contact['controls'].primary.value
                              ?.isManagerDispatched === true
                          "
                        >
                          <i
                            class="fa fa-fw fa-home"
                            (click)="
                              returnContact(
                                contact['controls'].primary.value?.managerId
                              )
                            "
                          ></i>
                        </span>
                        <span
                          class="input-icon text-right"
                          style="font-size: 12px"
                          *ngIf="contact['controls'].primary.value === null"
                        >
                        </span>
                      </div>
                      <span class="input-icon" style="width: 32px">
                        <i
                          class="fa fa-fw fa-id-card"
                          (click)="
                            goToContact(
                              contact['controls'].primary.value.managerId
                            )
                          "
                        ></i>
                      </span>
                      <span class="row-input-contact">
                        <p-autoComplete
                          class="ml-contact-input"
                          formControlName="manager"
                          [dropdown]="true"
                          optionLabel="managerFullName"
                          appendTo="body"
                          [virtualScroll]="true"
                          [virtualScrollItemSize]="34"
                        >
                        </p-autoComplete>
                      </span>
                      <div class="section-element">
                        <div class="label business-mobile">
                          <span
                            [translate]="'MONLOG.LABEL.BUSINESS_MOBILE'"
                          ></span>
                        </div>
                        <a
                          href="tel:9-1-{{
                            contact['controls'].primary.value
                              ?.managerBusinessMobile
                          }}"
                          >{{
                            contact["controls"].primary.value
                              ?.managerBusinessMobile
                          }}</a
                        >
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </p-panel>

            <p-panel
              header="{{ 'MONLOG.LABEL.ACTIONS_TAKEN' | translate }}"
              styleclass="mon-log-panel"
              [toggleable]="true"
              *ngIf="
                monLog.id &&
                (monLog.logCategory === 'MR' || monLog.logCategory === 'AE')
              "
            >
              <div>
                <div *ngIf="canEdit === true">
                  <div class="no-padding-top-bottom">
                    <i
                      class="fa fa-fw fa-clock-o"
                      style="font-size: 1.5em"
                      title="Add Default Entry"
                      (click)="addMaintenanceEntry()"
                    ></i>
                    &nbsp;
                    <i
                      class="fa fa-fw fa-check-circle"
                      style="font-size: 1.5em"
                      title="Resolve Log"
                      (click)="addMaintenanceEntry(true)"
                    ></i>
                  </div>
                  <div class="no-padding-top-bottom">
                    <button
                      pButton
                      label="{{ 'COMMON.LABEL.BUTTONS.ADD' | translate }}"
                      icon="fa fa-fw fa-plus"
                      (click)="showEntryForm(true)"
                    ></button>
                  </div>
                </div>
                <div *ngFor="let entry of monLog.MonLogEntries">
                  <app-mon-logs-entry
                    [entry]="entry"
                    [writeAccess]="canEdit"
                    (editEvent)="editEntry($event, true)"
                    (deleteEvent)="deleteEntry($event, true)"
                  >
                  </app-mon-logs-entry>
                </div>
              </div>
            </p-panel>
            <br />
          </p-panel>
        </p-tabPanel>

        <p-tabPanel
          header="{{ 'MONLOG.LABEL.ALARMS' | translate }}"
          *ngIf="monLog.id && isAlarm === true"
        >
          <p-panel
            header="{{ 'MONLOG.LABEL.SECTION_TWO' | translate }}"
            styleclass="mon-log-panel"
          >
            <div>
              <div
                style="text-align: right; height: 40px"
                *ngIf="canUserAddAlarm()"
              >
                <div
                  class="button-wrapper no-padding-top-bottom"
                  *ngIf="canEdit === true"
                >
                  <button
                    pButton
                    label="{{ 'COMMON.LABEL.BUTTONS.ADD' | translate }}"
                    icon="fa fa-fw fa-plus"
                    (click)="showEntryForm()"
                  ></button>
                </div>
              </div>
              <p-scrollPanel>
                <div *ngFor="let entry of monLog.MonLogAlarms">
                  <app-mon-logs-entry
                    [entry]="entry"
                    [writeAccess]="canUserAddAlarm()"
                    (editEvent)="editEntry($event)"
                    (deleteEvent)="deleteEntry($event)"
                  ></app-mon-logs-entry>
                </div>
              </p-scrollPanel>
            </div>
          </p-panel>
        </p-tabPanel>

        <p-tabPanel
          header="{{ 'MONLOG.LABEL.ENTRIES' | translate }}"
          *ngIf="
            monLog.id &&
            monLog.logCategory !== 'MR' &&
            monLog.logCategory !== 'AE'
          "
        >
          <p-panel
            header="{{ 'MONLOG.LABEL.SECTION_THREE' | translate }}"
            styleclass="mon-log-panel"
          >
            <div>
              <div
                style="text-align: right; height: 40px"
                *ngIf="canEdit === true"
              >
                <div class="button-wrapper no-padding-top-bottom">
                  <button
                    pButton
                    label="{{ 'COMMON.LABEL.BUTTONS.ADD' | translate }}"
                    icon="fa fa-fw fa-plus"
                    (click)="showEntryForm(true)"
                  ></button>
                </div>
              </div>
              <p-scrollPanel>
                <div
                  style="word-wrap: break-word"
                  *ngFor="let entry of monLog.MonLogEntries"
                >
                  <app-mon-logs-entry
                    [entry]="entry"
                    [writeAccess]="canEdit"
                    (editEvent)="editEntry($event, true)"
                    (deleteEvent)="deleteEntry($event, true)"
                  >
                  </app-mon-logs-entry>
                </div>
              </p-scrollPanel>
            </div>
          </p-panel>
        </p-tabPanel>

        <p-tabPanel
          header="{{ 'MONLOG.LABEL.NOTIFICATIONS_AND_AUDIT' | translate }}"
        >
          <div>
            <p-scrollPanel
              [style]="{ width: '100%' }"
              styleclass="notify-scroll"
            >
              <div>
                <div class="no-padding">
                  <p-panel
                    header="{{
                      'MONLOG.LABEL.CALLOUT_INFORMATION' | translate
                    }}"
                    styleclass="mon-log-panel"
                  >
                    <div
                      class="no-padding-top-bottom"
                      formArrayName="MonLogCallouts"
                      *ngFor="let callout of getCallouts(); let j = index"
                    >
                      <div
                        formGroupName="{{ j }}"
                        class="callout"
                        style="border-bottom: 1px solid #dedede"
                      >
                        <div class="section">
                          <span>
                            {{ callout.controls["firstName"].value }}
                            {{ callout.controls["lastName"].value }}
                          </span>
                        </div>
                        <div class="section">
                          <span
                            class="label"
                            [translate]="'MONLOG.LABEL.CALLOUT_DATE'"
                          ></span>
                          <span class="section-input">
                            <p-calendar
                              formControlName="startDt"
                              [showTime]="true"
                              hourFormat="12"
                            ></p-calendar>
                          </span>
                        </div>
                        <div class="section">
                          <span
                            class="label"
                            [translate]="'MONLOG.LABEL.RETURN_DATE'"
                          ></span>
                          <span class="section-input">
                            <p-calendar
                              formControlName="endDt"
                              [showTime]="true"
                              hourFormat="12"
                              [minDate]="callout.controls['startDt'].value"
                            ></p-calendar>
                          </span>
                          <span
                            class="input-icon"
                            style="margin-left: 8px"
                            *ngIf="canEdit === true"
                          >
                            <i
                              class="fa fa-fw fa-trash"
                              (click)="
                                deleteCallout(callout.controls['id'].value)
                              "
                            ></i>
                          </span>
                        </div>
                      </div>
                    </div>
                  </p-panel>
                </div>

                <br />
                <br />
                <div class="no-padding">
                  <p-panel
                    header="{{ 'MONLOG.LABEL.NOTIFICATIONS' | translate }}"
                    styleclass="mon-log-panel"
                  >
                    <div
                      *ngFor="let a of monLog.MonLogNotifications"
                      style="word-wrap: break-word; width: 600px"
                    >
                      <strong
                        >{{ a.time | date : "yyyy-MM-dd HH:mm:ss" }} -
                        {{ a.user }}:</strong
                      >
                      {{ a.note }}
                    </div>
                  </p-panel>
                </div>
                <br />
                <br />
                <div class="no-padding">
                  <p-panel
                    header="{{ 'MONLOG.LABEL.AUDIT_LOG' | translate }}"
                    styleclass="mon-log-panel"
                  >
                    <div
                      *ngFor="let a of monLog.MonLogAudits"
                      style="word-wrap: break-word; width: 600px"
                    >
                      <strong
                        >{{ a.time | date : "yyyy-MM-dd HH:mm:ss" }} -
                        {{ a.user }}:</strong
                      >
                      {{ a.note }}
                    </div>
                  </p-panel>
                </div>
              </div>
            </p-scrollPanel>
          </div>
        </p-tabPanel>

        @if (this.canViewEventReview() && this.doesAnyTypeRequireReview()) {
        <p-tabPanel header="{{ 'MONLOG.LABEL.EVENT_REVIEW' | translate }}">
          <div>
            @if (this.canCreateEventReviewEntries()){
            <div class="open-dialog">
              <div class="button-wrapper no-padding-top-bottom">
                <button
                  pButton
                  label="{{ 'MONLOG.LABEL.BUTTONS.REVIEW' | translate }}"
                  icon="fa fa-fw fa-plus"
                  (click)="addEventReviewEntry()"
                ></button>
              </div>
            </div>
            }
            <p-scrollPanel>
              <div class="word-wrap">
                @for(entry of monLog.ControlRoomEventReviewEntries; track
                entry.id) {
                <app-event-review-entry
                  [entry]="entry"
                  (editEvent)="editEventReviewEntry(entry)"
                  (entryDeletedEvent)="crerEntryDeleted(entry)"
                >
                </app-event-review-entry>
                }
              </div>
            </p-scrollPanel>
          </div>
        </p-tabPanel>
        }
      </p-tabView>
    </div>

    <div
      id="mon-log-small"
      *ngIf="ifDeviceMatches([DeviceSize.XS, DeviceSize.SM])"
    >
      <br />
      <p-panel
        [showHeader]="true"
        header="General"
        [toggleable]="true"
        styleclass="mon-log-panel"
        toggler="header"
      >
        <div>
          <div class="no-padding-top-bottom">
            <div class="no-padding" *ngIf="monLog.id">
              <span class="no-padding-top-bottom" style="font-weight: 700"
                >{{ "MONLOG.LABEL.CREATED_BY" | translate }}:</span
              >
              <span class="no-padding-top-bottom"
                >{{ monLog.createdBy }} on
                {{ monLog.createdAt | date : "MM/dd/yyyy hh:mm:ss a" }}</span
              >
              <span class="no-padding-top-bottom" style="font-weight: 700"
                >{{ "MONLOG.LABEL.LAST_UPDATED" | translate }}:</span
              >
              <span class="no-padding-top-bottom">{{
                monLog.updatedAt | date : "MM/dd/yyyy hh:mm:ss a"
              }}</span>
            </div>
            <div class="no-padding" *ngIf="monLog.id">
              <span class="no-padding-top-bottom" style="font-weight: 700"
                >{{ "MONLOG.LABEL.CURRENT_STATUS" | translate }}:</span
              >
              <span
                class="no-padding-top-bottom"
                *ngIf="monLog.logStatus === 'O'"
                >{{ "MONLOG.LABEL.OPEN" | translate }}</span
              >
              <span
                class="no-padding-top-bottom"
                *ngIf="monLog.logStatus === 'A'"
                >{{ "MONLOG.LABEL.AWAITING_REVIEW" | translate }}</span
              >
              <span
                class="no-padding-top-bottom"
                *ngIf="monLog.logStatus === 'C'"
                >{{ "MONLOG.LABEL.REVIEW_COMPLETE" | translate }}</span
              >
              <span
                class="no-padding-top-bottom"
                *ngIf="monLog.logStatus === 'R'"
                >{{ "MONLOG.LABEL.RESOLVED" | translate }}</span
              >
              <span
                class="no-padding-top-bottom"
                *ngIf="monLog.reviewRequestedDt"
                style="font-weight: 700"
                >{{ "MONLOG.LABEL.REVIEW_REQUESTED" | translate }}:</span
              >
              <span
                class="no-padding-top-bottom"
                *ngIf="monLog.reviewRequestedDt"
                >{{
                  monLog.reviewRequestedDt | date : "yyyy-MM-dd HH:mm:ss"
                }}</span
              >
            </div>
            <div class="no-padding">
              <span class="no-padding-top-bottom" style="font-weight: 700"
                >{{ "MONLOG.LABEL.STATUS_SUMMARY" | translate }}:</span
              >
              <span class="no-padding-top-bottom">
                {{ monLog.statusSummary }}
              </span>
            </div>
            <div class="no-padding" *ngIf="monLog.id">
              <span
                class="no-padding-top-bottom"
                *ngIf="monLog.reviewCompletedDt"
                style="font-weight: 700"
                >{{ "MONLOG.LABEL.REVIEW_COMPLETED" | translate }}:</span
              >
              <span
                class="no-padding-top-bottom"
                *ngIf="monLog.reviewCompletedDt"
                >{{
                  monLog.reviewCompletedDt | date : "yyyy-MM-dd HH:mm:ss"
                }}</span
              >
              <span
                class="no-padding-top-bottom"
                style="font-weight: 700"
                *ngIf="monLog.reviewer"
                >{{ "MONLOG.LABEL.REVIEWER" | translate }}:</span
              >
              <span class="no-padding-top-bottom" *ngIf="monLog.reviewer">{{
                monLog.reviewer
              }}</span>
            </div>
          </div>
          <span class="no-padding-top-bottom"
            ><span class="asterisk-call-type">*</span
            >{{ "MONLOG.LABEL.TYPE_OF_CALL" | translate }}:</span
          >
          <span class="no-padding-top-bottom">
            <p-multiSelect
              [options]="types"
              [maxSelectedLabels]="10"
              (onChange)="callTypeChange($event)"
              formControlName="typeOfCalls"
            ></p-multiSelect>
            <app-inline-validation
              field="typeOfCalls"
              [control]="getBaseFormChild('typeOfCalls')"
              [validationMessages]="validationMessages"
              [validationParams]="validationParams"
            ></app-inline-validation>
          </span>
          <div
            class="no-padding"
            *ngIf="monLog.logCategory !== 'MR' && monLog.logCategory !== 'AE'"
          >
            <span class="no-padding-top-bottom"
              >{{ "MONLOG.LABEL.CUSTOMER_OUTAGE" | translate }}:</span
            >
            <span class="no-padding-top-bottom">
              <div>
                <p-radioButton
                  name="customerOutage"
                  [value]="true"
                  label="{{ 'COMMON.LABEL.BUTTONS.YES' | translate }}"
                  formControlName="customerOutage"
                ></p-radioButton>
              </div>
              <div>
                <p-radioButton
                  name="customerOutage"
                  [value]="false"
                  label="{{ 'COMMON.LABEL.BUTTONS.NO' | translate }}"
                  formControlName="customerOutage"
                ></p-radioButton>
              </div>
            </span>
          </div>

          <div
            class="no-padding"
            *ngIf="monLog.logCategory !== 'MR' && monLog.logCategory !== 'AE'"
          >
            <span class="no-padding-top-bottom"
              >{{ "MONLOG.LABEL.CALLOUT_REQUIRED" | translate }}:</span
            >
            <span class="no-padding-top-bottom">
              <div>
                <p-radioButton
                  name="calloutRequired"
                  [value]="true"
                  label="{{ 'COMMON.LABEL.BUTTONS.YES' | translate }}"
                  formControlName="calloutRequired"
                ></p-radioButton>
              </div>
              <div>
                <p-radioButton
                  name="calloutRequired"
                  [value]="false"
                  label="{{ 'COMMON.LABEL.BUTTONS.NO' | translate }}"
                  formControlName="calloutRequired"
                ></p-radioButton>
              </div>
              <app-inline-validation
                field="calloutRequired"
                [control]="getBaseFormChild('calloutRequired')"
                [validationMessages]="validationMessages"
                [validationParams]="validationParams"
              ></app-inline-validation>
            </span>
          </div>

          <div
            class="no-padding"
            *ngIf="monLog.logCategory !== 'MR' && monLog.logCategory !== 'AE'"
          >
            <span class="no-padding-top-bottom"
              >{{ "MONLOG.LABEL.ONE_CALL_NO" | translate }}:</span
            >
            <span class="no-padding-top-bottom">
              <input pInputText formControlName="oneCallNumber" />
            </span>
          </div>

          <div
            class="no-padding"
            *ngIf="monLog.logCategory !== 'MR' && monLog.logCategory !== 'AE'"
          >
            <span class="no-padding-top-bottom"
              >{{ "MONLOG.LABEL.TICKET_NUMBER" | translate }}:</span
            >
            <span class="no-padding-top-bottom">
              <input pInputText formControlName="ticketNumber" />
            </span>
          </div>

          <div
            class="no-padding"
            *ngIf="monLog.logCategory !== 'MR' && monLog.logCategory !== 'AE'"
          >
            <span class="no-padding-top-bottom"
              >{{ "MONLOG.LABEL.TIME_BEGAN" | translate }}:</span
            >
            <span class="no-padding-top-bottom">
              <div class="ui-inputgroup">
                <p-calendar
                  [timeOnly]="true"
                  [showSeconds]="true"
                  hourFormat="12"
                  formControlName="timeBegan"
                >
                </p-calendar>
                <span class="addon"
                  ><i
                    class="fa fa-clock-o clock-icon"
                    (click)="setTimeBegan()"
                  ></i
                ></span>
              </div>
            </span>
          </div>

          <div
            class="no-padding"
            *ngIf="monLog.logCategory !== 'MR' && monLog.logCategory !== 'AE'"
          >
            <span class="no-padding-top-bottom"
              >{{ "MONLOG.LABEL.TIME_ON_SITE" | translate }}:</span
            >
            <span class="no-padding-top-bottom">
              <div class="ui-inputgroup">
                <p-calendar
                  [timeOnly]="true"
                  hourFormat="12"
                  formControlName="timeOnSite"
                  [showSeconds]="true"
                >
                </p-calendar>
                <span class="addon"
                  ><i
                    class="fa fa-clock-o clock-icon"
                    (click)="setTimeOnSite()"
                  ></i
                ></span>
              </div>
            </span>
          </div>

          <div
            class="no-padding"
            *ngIf="monLog.logCategory !== 'MR' && monLog.logCategory !== 'AE'"
          >
            <span class="no-padding-top-bottom"
              >{{ "MONLOG.LABEL.FALSE_ALARM" | translate }}:</span
            >
            <span class="no-padding-top-bottom">
              <div>
                <p-radioButton
                  name="falseAlarm"
                  [value]="true"
                  label="{{ 'COMMON.LABEL.BUTTONS.YES' | translate }}"
                  formControlName="falseAlarm"
                ></p-radioButton>
              </div>
              <div>
                <p-radioButton
                  name="falseAlarm"
                  [value]="false"
                  label="{{ 'COMMON.LABEL.BUTTONS.NO' | translate }}"
                  formControlName="falseAlarm"
                ></p-radioButton>
              </div>
            </span>
          </div>

          <div class="no-padding" *ngIf="showAEWorkTypes === true">
            <span class="no-padding-top-bottom"
              >{{ "MONLOG.LABEL.WORK_TYPE" | translate }}:</span
            >
            <span class="no-padding-top-bottom">
              <p-multiSelect
                [options]="aeWorkTypes"
                [maxSelectedLabels]="10"
                formControlName="workTypes"
              ></p-multiSelect>
              <app-inline-validation
                field="workType"
                [control]="getBaseFormChild('workTypes')"
                [validationMessages]="validationMessages"
                [validationParams]="validationParams"
              ></app-inline-validation>
            </span>
          </div>

          <div
            class="no-padding"
            *ngIf="
              showMRWorkTypes === true &&
              (monLog.logCategory === 'MR' || monLog.logCategory === 'AE')
            "
          >
            <span class="no-padding-top-bottom"
              >{{ "MONLOG.LABEL.WORK_TYPE" | translate }}:</span
            >
            <span class="no-padding-top-bottom">
              <p-multiSelect
                [options]="mrWorkTypes"
                [maxSelectedLabels]="10"
                formControlName="workTypes"
              ></p-multiSelect>
              <app-inline-validation
                field="workType"
                [control]="getBaseFormChild('workTypes')"
                [validationMessages]="validationMessages"
                [validationParams]="validationParams"
              ></app-inline-validation>
            </span>
          </div>

          <div
            class="no-padding"
            *ngIf="monLog.logCategory === 'MR' || monLog.logCategory === 'AE'"
          >
            <span class="no-padding-top-bottom"
              >{{ "MONLOG.LABEL.BACK_IN_SERVICE" | translate }}:</span
            >
            <span class="no-padding-top-bottom">
              <div>
                <p-radioButton
                  name="backInService"
                  [value]="true"
                  label="{{ 'COMMON.LABEL.BUTTONS.YES' | translate }}"
                  formControlName="backInService"
                ></p-radioButton>
              </div>
              <div>
                <p-radioButton
                  name="backInService"
                  [value]="false"
                  label="{{ 'COMMON.LABEL.BUTTONS.NO' | translate }}"
                  formControlName="backInService"
                ></p-radioButton>
              </div>
            </span>
          </div>

          <div
            class="no-padding"
            *ngIf="
              showGasReleaseType === true &&
              monLog.logCategory !== 'MR' &&
              monLog.logCategory !== 'AE'
            "
          >
            <span class="no-padding-top-bottom"
              >{{ "MONLOG.LABEL.GAS_RELEASE_TYPE" | translate }}:</span
            >
            <span class="no-padding-top-bottom">
              <p-dropdown
                [options]="gasReleaseTypes"
                formControlName="gasReleaseType"
              ></p-dropdown>
              <app-inline-validation
                field="gasReleaseType"
                [control]="getBaseFormChild('gasReleaseType')"
                [validationMessages]="validationMessages"
                [validationParams]="validationParams"
              ></app-inline-validation>
            </span>
          </div>
          <div
            class="no-padding"
            *ngIf="
              showSeverity === true &&
              monLog.logCategory !== 'MR' &&
              monLog.logCategory !== 'AE'
            "
          >
            <span class="no-padding-top-bottom"
              >{{ "MONLOG.LABEL.SEVERITY" | translate }}:</span
            >
            <span class="no-padding-top-bottom">
              <p-dropdown
                [options]="commFailSeverities"
                formControlName="commFailSeverity"
              ></p-dropdown>
              <app-inline-validation
                field="commFailSeverity"
                [control]="getBaseFormChild('commFailSeverity')"
                [validationMessages]="validationMessages"
                [validationParams]="validationParams"
              ></app-inline-validation>
            </span>
          </div>
          <div
            class="no-padding"
            *ngIf="
              showNotam === true &&
              monLog.logCategory !== 'MR' &&
              monLog.logCategory !== 'AE'
            "
          >
            <span class="no-padding-top-bottom"
              >{{ "MONLOG.LABEL.NOTAM" | translate }}:</span
            >
            <span class="no-padding-top-bottom">
              <input pInputText formControlName="notam" />
            </span>
          </div>
          <div
            class="no-padding"
            *ngIf="
              showNotam === true &&
              monLog.logCategory !== 'MR' &&
              monLog.logCategory !== 'AE'
            "
          >
            <span class="no-padding-top-bottom"
              >{{ "MONLOG.LABEL.EXPIRE_DT" | translate }}:</span
            >
            <span class="no-padding-top-bottom">
              <p-calendar formControlName="expireDt"></p-calendar>
            </span>
          </div>
        </div>
      </p-panel>
      <br />

      <p-panel
        header="{{ 'MONLOG.LABEL.CALLER_LOCATION_INFO' | translate }}"
        [showHeader]="true"
        styleclass="mon-log-panel"
        [toggleable]="true"
        toggler="header"
      >
        <div>
          <div class="no-padding" *ngIf="monLog.logCategory === 'N'">
            <span class="no-padding-top-bottom"
              >{{ "CONTACT.LABEL.FIRST_NAME" | translate }}:</span
            >
            <span class="no-padding-top-bottom">
              <input pInputText formControlName="firstName" />
            </span>
            <span class="no-padding-top-bottom"
              >{{ "CONTACT.LABEL.LAST_NAME" | translate }}:</span
            >
            <span class="no-padding-top-bottom">
              <input pInputText formControlName="lastName" />
            </span>
            <span class="no-padding-top-bottom"
              >{{ "MONLOG.LABEL.PHONE" | translate }}:</span
            >
            <span class="no-padding-top-bottom">
              <p-inputMask
                mask="999-999-9999"
                id="businessPhone"
                formControlName="phone"
                (onBlur)="fixMask('phone')"
              >
              </p-inputMask>
            </span>
            <span class="no-padding-top-bottom"
              >{{ "MONLOG.LABEL.STREET" | translate }}:</span
            >
            <span class="no-padding-top-bottom">
              <input pInputText formControlName="street" />
            </span>
          </div>
          <div
            class="no-padding"
            *ngIf="
              monLog.logCategory === 'I' ||
              monLog.logCategory === 'MR' ||
              monLog.logCategory === 'AE'
            "
          >
            <div class="no-padding-top-bottom">
              <span class="label"
                >{{ "MONLOG.LABEL.INTERNAL_CONTACT_ONE" | translate }}:</span
              >
              <span
                class="input-icon text-right"
                *ngIf="
                  monLog.logCategory === 'MR' || monLog.logCategory === 'AE'
                "
              >
                <i
                  class="fa fa-fw fa-phone-square"
                  (click)="addControllerEntry(0)"
                ></i>
              </span>
              <span>
                <p-autoComplete
                  [suggestions]="filteredContacts"
                  formControlName="internalOne"
                  [dropdown]="true"
                  optionLabel="fullName"
                  (completeMethod)="contactSearch($event, true)"
                  appendTo="body"
                  [forceSelection]="true"
                  [virtualScroll]="true"
                  [virtualScrollItemSize]="34"
                >
                </p-autoComplete>
              </span>
            </div>
            <div class="no-padding-top-bottom">
              <span class="label"
                >{{ "MONLOG.LABEL.INTERNAL_CONTACT_TWO" | translate }}:</span
              >
              <span
                class="input-icon text-right"
                *ngIf="
                  monLog.logCategory === 'MR' || monLog.logCategory === 'AE'
                "
              >
                <i
                  class="fa fa-fw fa-phone-square"
                  (click)="addControllerEntry(1)"
                ></i>
              </span>
              <span>
                <p-autoComplete
                  [suggestions]="filteredContacts"
                  formControlName="internalTwo"
                  [dropdown]="true"
                  optionLabel="fullName"
                  (completeMethod)="contactSearch($event, true)"
                  appendTo="body"
                  [forceSelection]="true"
                  [virtualScroll]="true"
                  [virtualScrollItemSize]="34"
                >
                </p-autoComplete>
              </span>
            </div>
          </div>
          <div
            class="no-padding"
            *ngIf="monLog.logCategory !== 'MR' && monLog.logCategory !== 'AE'"
          >
            <span class="no-padding-top-bottom"
              >{{ "LOCATION.LABEL.STATE" | translate }}:</span
            >
            <span class="no-padding-top-bottom">
              <p-autoComplete
                [suggestions]="filteredStates"
                (completeMethod)="stateSearch($event)"
                (onSelect)="selectState($event, this.form)"
                formControlName="state"
                [dropdown]="true"
                appendTo="body"
                [virtualScroll]="true"
                [virtualScrollItemSize]="34"
              >
              </p-autoComplete>
            </span>
          </div>
          <div
            class="no-padding"
            *ngIf="monLog.logCategory !== 'MR' && monLog.logCategory !== 'AE'"
          >
            <span class="no-padding-top-bottom"
              >{{ "LOCATION.LABEL.COUNTY" | translate }}:</span
            >
            <span class="no-padding-top-bottom">
              <p-autoComplete
                [suggestions]="filteredCounties"
                (completeMethod)="countySearch($event, this.form)"
                formControlName="county"
                [dropdown]="true"
                optionLabel="county"
                (onSelect)="selectCounty($event, this.form)"
                appendTo="body"
                [virtualScroll]="true"
                [virtualScrollItemSize]="34"
              >
              </p-autoComplete>
            </span>
          </div>
          <div
            class="no-padding"
            *ngIf="monLog.logCategory !== 'MR' && monLog.logCategory !== 'AE'"
          >
            <span class="no-padding-top-bottom"
              >{{ "LOCATION.LABEL.TOWNSHIP" | translate }}:</span
            >
            <span class="no-padding-top-bottom">
              <p-autoComplete
                [suggestions]="filteredTownships"
                (completeMethod)="townshipSearch($event, this.form)"
                formControlName="township"
                [dropdown]="true"
                optionLabel="township"
                (onSelect)="selectTownship($event, this.form)"
                appendTo="body"
                [virtualScroll]="true"
                [virtualScrollItemSize]="34"
              >
              </p-autoComplete>
            </span>
          </div>
          <div
            class="no-padding"
            *ngIf="monLog.logCategory !== 'MR' && monLog.logCategory !== 'AE'"
          >
            <span class="no-padding-top-bottom"
              >{{ "LOCATION.LABEL.CITY" | translate }}:</span
            >
            <span class="no-padding-top-bottom">
              <input pInputText formControlName="city" />
            </span>
          </div>
        </div>
      </p-panel>
      <br />
      <p-panel
        header="{{ 'MONLOG.LABEL.FACILITY_INFO' | translate }}"
        styleclass="mon-log-panel"
        [toggleable]="true"
        [showHeader]="true"
        toggler="header"
      >
        <div>
          <span class="no-padding-top-bottom"
            ><button
              pButton
              icon="fa fa-fw fa-eraser"
              *ngIf="isEditting === true"
              pTooltip="Clear Facility and Location"
              (click)="resetFacility()"
            ></button
          ></span>
          <span class="no-padding-top-bottom"
            >{{ "LOCATION.LABEL.FACILITY_TYPE" | translate }}:</span
          >
          <span class="no-padding-top-bottom">
            <p-autoComplete
              [suggestions]="filteredFacilityTypes"
              (completeMethod)="facilityTypeSearch($event)"
              formControlName="facilityType"
              [dropdown]="true"
              [forceSelection]="true"
              (onSelect)="facilityTypeSelected($event)"
              appendTo="body"
              [virtualScroll]="true"
              [virtualScrollItemSize]="34"
              *ngIf="!facilitiesLoading"
            >
            </p-autoComplete>
            <p-progressSpinner
              *ngIf="facilitiesLoading"
              [style]="{ width: '50px', height: '50px' }"
            ></p-progressSpinner>
          </span>
          <div class="no-padding">
            <span class="no-padding-top-bottom"
              >{{ "LOCATION.LABEL.FACILITY_NAME" | translate }}:</span
            >
            <span class="no-padding-top-bottom">
              <p-autoComplete
                [suggestions]="filteredFacilityNames"
                (completeMethod)="facilityNameSearch($event)"
                formControlName="facilityName"
                [dropdown]="true"
                optionLabel="facilityName"
                (onSelect)="facilityNameSelected($event)"
                [forceSelection]="true"
                dropdownMode="current"
                appendTo="body"
                [virtualScroll]="true"
                [virtualScrollItemSize]="34"
                *ngIf="!facilitiesLoading"
              >
              </p-autoComplete>
              <p-progressSpinner
                *ngIf="facilitiesLoading"
                [style]="{ width: '50px', height: '50px' }"
              ></p-progressSpinner>
            </span>
          </div>
          <div class="no-padding">
            <span class="no-padding-top-bottom"
              >{{ "LOCATION.LABEL.FACILITY_UID" | translate }}:</span
            >
            <span class="no-padding-top-bottom">
              <p-autoComplete
                [suggestions]="filteredFacilityIds"
                (completeMethod)="facilityIdSearch($event)"
                formControlName="facilityUid"
                [dropdown]="true"
                optionLabel="facilityUid"
                [forceSelection]="true"
                dropdownMode="current"
                appendTo="body"
                [virtualScroll]="true"
                [virtualScrollItemSize]="34"
                *ngIf="!facilitiesLoading"
              >
              </p-autoComplete>
              <p-progressSpinner
                *ngIf="facilitiesLoading"
                [style]="{ width: '50px', height: '50px' }"
              ></p-progressSpinner>
            </span>
          </div>
          <div class="no-padding">
            <span class="no-padding-top-bottom"
              >{{ "MONLOG.LABEL.PIPELINE" | translate }}:</span
            >
            <span class="no-padding-top-bottom">
              <input pInputText formControlName="pipeline" />
            </span>
          </div>
          <div class="-no-padding">
            <span class="no-padding-top-bottom"
              >{{ "LOCATION.LABEL.AOR" | translate }}:</span
            >
            <span class="no-padding-top-bottom">
              <input pInputText formControlName="aor" />
            </span>
          </div>
          <div class="no-padding">
            <span class="no-padding-top-bottom"
              ><span class="asterisk-business-unit">*</span> Business
              Unit:</span
            >
            <span class="no-padding-top-bottom">
              <p-dropdown formControlName="businessUnit"></p-dropdown>
            </span>
          </div>
        </div>
      </p-panel>
      <br />
      <p-panel
        header="{{ 'MONLOG.LABEL.PRIMARY_CONTACT_INFO' | translate }}"
        styleclass="mon-log-panel"
        [showHeader]="true"
        [toggleable]="true"
        toggler="header"
      >
        <div>
          <div
            class="no-padding-top-bottom"
            formArrayName="MonLogContacts"
            *ngFor="
              let contact of form.get('MonLogContacts')['controls'];
              let i = index
            "
          >
            <div [formGroupName]="i">
              <div class="no-padding-top-bottom">
                <span
                  class="no-padding-top-bottom"
                  style="border-top: 1px solid #dedede"
                  ><button
                    pButton
                    icon="fa fa-trash"
                    pTooltip="Remove Contact Row"
                    *ngIf="i !== 0 && isEditting === true"
                    (click)="removeContactRow(i)"
                  ></button
                ></span>
                <span
                  class="no-padding-top-bottom"
                  *ngIf="i === 0"
                  [translate]="'MONLOG.LABEL.PRIMARY_CONTACT'"
                ></span>
                <span
                  class="no-padding-top-bottom"
                  *ngIf="i !== 0"
                  [translate]="'MONLOG.LABEL.OTHER_CONTACT'"
                  [translateParams]="{ index: i }"
                ></span>
                <span
                  class="input-icon"
                  *ngIf="
                    contact['controls'].primary.value?.isPrimaryDispatched ===
                    false
                  "
                >
                  <i
                    class="fa fa-fw fa-phone-square"
                    (click)="
                      dispatchContact(
                        contact['controls'].primary.value?.primaryId
                      )
                    "
                  ></i>
                </span>
                <span
                  class="input-icon"
                  *ngIf="
                    contact['controls'].primary.value?.isPrimaryDispatched ===
                    true
                  "
                >
                  <i
                    class="fa fa-fw fa-home"
                    (click)="
                      returnContact(
                        contact['controls'].primary.value?.primaryId
                      )
                    "
                  ></i>
                </span>
                <span
                  class="input-icon"
                  *ngIf="contact['controls'].primary.value === null"
                >
                </span>
                <span>
                  <p-autoComplete
                    [suggestions]="filteredContacts"
                    formControlName="primary"
                    [dropdown]="true"
                    optionLabel="primaryFullName"
                    (onSelect)="contactSelected(i, $event)"
                    (completeMethod)="contactSearch($event)"
                    appendTo="body"
                    [forceSelection]="true"
                    [virtualScroll]="true"
                    [virtualScrollItemSize]="34"
                  >
                  </p-autoComplete>
                </span>
                <span class="input-icon">
                  <i
                    class="fa fa-fw fa-id-card"
                    (click)="
                      goToContact(contact['controls'].primary.value.primaryId)
                    "
                  ></i>
                </span>
              </div>
              <div class="no-padding-top-bottom">
                <span
                  class="no-padding-top-bottom"
                  *ngIf="i === 0"
                  [translate]="'MONLOG.LABEL.SUPERVISOR'"
                ></span>
                <span
                  class="no-padding-top-bottom"
                  *ngIf="i !== 0"
                  [translate]="'MONLOG.LABEL.OTHER_SUPERVISOR'"
                  [translateParams]="{ index: i }"
                ></span>
                <span
                  class="input-icon"
                  *ngIf="
                    contact['controls'].primary.value
                      ?.isSupervisorDispatched === false
                  "
                >
                  <i
                    class="fa fa-fw fa-phone-square"
                    (click)="
                      dispatchContact(
                        contact['controls'].primary.value?.supervisorId
                      )
                    "
                  ></i>
                </span>
                <span
                  class="input-icon"
                  *ngIf="
                    contact['controls'].primary.value
                      ?.isSupervisorDispatched === true
                  "
                >
                  <i
                    class="fa fa-fw fa-home"
                    (click)="
                      returnContact(
                        contact['controls'].primary.value?.supervisorId
                      )
                    "
                  ></i>
                </span>
                <span
                  class="input-icon"
                  *ngIf="contact['controls'].primary.value === null"
                >
                </span>
                <span>
                  <p-autoComplete
                    formControlName="supervisor"
                    [dropdown]="true"
                    optionLabel="supervisorFullName"
                    appendTo="body"
                    [virtualScroll]="true"
                    [virtualScrollItemSize]="34"
                  ></p-autoComplete>
                </span>
                <span class="input-icon">
                  <i
                    class="fa fa-fw fa-id-card"
                    (click)="
                      goToContact(
                        contact['controls'].primary.value.supervisorId
                      )
                    "
                  ></i>
                </span>
              </div>
              <div class="no-padding-top-bottom">
                <span
                  class="no-padding-top-bottom"
                  *ngIf="i === 0"
                  [translate]="'MONLOG.LABEL.MANAGER'"
                ></span>
                <span
                  class="no-padding-top-bottom"
                  *ngIf="i !== 0"
                  [translate]="'MONLOG.LABEL.OTHER_MANAGER'"
                  [translateParams]="{ index: i }"
                ></span>
                <span
                  class="input-icon"
                  *ngIf="
                    contact['controls'].primary.value?.isManagerDispatched ===
                    false
                  "
                >
                  <i
                    class="fa fa-fw fa-phone-square"
                    (click)="
                      dispatchContact(
                        contact['controls'].primary.value.managerId
                      )
                    "
                  ></i>
                </span>
                <span
                  class="input-icon"
                  *ngIf="
                    contact['controls'].primary.value?.isManagerDispatched ===
                    true
                  "
                >
                  <i
                    class="fa fa-fw fa-home"
                    (click)="
                      returnContact(
                        contact['controls'].primary.value?.managerId
                      )
                    "
                  ></i>
                </span>
                <span
                  class="input-icon"
                  *ngIf="contact['controls'].primary.value === null"
                >
                </span>
                <span>
                  <p-autoComplete
                    formControlName="manager"
                    [dropdown]="true"
                    optionLabel="managerFullName"
                    appendTo="body"
                    [virtualScroll]="true"
                    [virtualScrollItemSize]="34"
                  >
                  </p-autoComplete>
                </span>
                <span class="input-icon">
                  <i
                    class="fa fa-fw fa-id-card"
                    (click)="
                      goToContact(contact['controls'].primary.value.managerId)
                    "
                  ></i>
                </span>
              </div>
            </div>
          </div>
        </div>
      </p-panel>
      <br />
      <p-panel
        header="{{ 'MONLOG.LABEL.ENTRIES' | translate }}"
        [toggleable]="true"
        toggler="header"
      >
        <div>
          <div
            style="word-wrap: break-word"
            *ngFor="let entry of monLog.MonLogEntries"
          >
            <app-mon-logs-entry
              [entry]="entry"
              [writeAccess]="false"
              (editEvent)="editEntry($event, true)"
              (deleteEvent)="deleteEntry($event, true)"
            ></app-mon-logs-entry>
          </div>
        </div>
      </p-panel>
    </div>
  </form>

  <app-mon-logs-add-entry (addEntrySaveEvent)="refresh()">
  </app-mon-logs-add-entry>

  <app-event-review-dialog [monLogId]="monLog?.id" (entryChangedEvent)="refresh()"></app-event-review-dialog>

  <app-email-dialog-container
    [(displayDialog)]="displayEmailDialog"
    [header]="emailDialogHeader"
    (sendEmailEvent)="emailMonLog($event)"
    (dialogHiddenEvent)="dialogHidden($event)"
  ></app-email-dialog-container>
</div>
