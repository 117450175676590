import { Injectable } from '@angular/core';
import { ShiftTurnover } from '../model/shift/shift-turnover';
import { ShiftGroup } from '../model/shift/shift-group';
import { ShiftType } from '../model/shift/shift-type';
import { BaseTableService } from './base-table.service';

@Injectable({
  providedIn: 'root'
})
export class ShiftTurnoverTableService extends BaseTableService<ShiftTurnover> {
  private availableGroups: ShiftGroup[];
  private availableTypes: ShiftType[];

  constructor() {
    super();
  }

  getAvailableGroups() {
    return this.availableGroups;
  }

  setAvailableGroups(groups: any[]) {
    this.availableGroups = groups;
  }

  getAvailableTypes() {
    return this.availableTypes;
  }

  setAvailableTypes(types: any[]) {
    this.availableTypes = types;
  }
}
