import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  signal,
} from '@angular/core';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { ConfirmationService } from 'primeng/api';
import { firstValueFrom } from 'rxjs';
import { CoreModule } from 'src/app/core/core.module';
import { ControlRoomEventReviewEntry } from 'src/app/model/monlogs/control-room-event-review-entry';
import { AuthApiService } from 'src/app/services/auth-api.service';
import { AuthService } from 'src/app/services/auth.service';
import { MonlogsApiService } from 'src/app/services/monlogs-api.service';

@Component({
  selector: 'app-event-review-entry',
  standalone: true,
  imports: [CommonModule, TranslateModule, CoreModule],
  templateUrl: './event-review-entry.component.html',
  styleUrl: './event-review-entry.component.scss',
})
export class EventReviewEntryComponent implements OnInit {
  @Input() entry: ControlRoomEventReviewEntry;
  @Output() editEvent = new EventEmitter<any>();
  @Output() entryDeletedEvent = new EventEmitter<any>();

  canEdit = signal(false);
  canDelete = signal(false);
  entryWorking = signal(false);

  constructor(
    private _authApi: AuthApiService,
    private _auth: AuthService,
    private _confirmationService: ConfirmationService,
    private _monLogApi: MonlogsApiService,
    private _translateService: TranslateService
  ) {}

  ngOnInit() {
    this.checkPermissions();
  }

  checkPermissions(): void {
    if (
      this._authApi.doesUserHaveClaim('EditAllControlRoomEventReviewEntries') ||
      (this._authApi.doesUserHaveClaim(
        'EditOwnControlRoomEventReviewEntries'
      ) &&
        this.isOwnEntry())
    ) {
      this.canEdit.set(true);
    }

    if (
      this._authApi.doesUserHaveClaim(
        'DeleteAllControlRoomEventReviewEntries'
      ) ||
      (this._authApi.doesUserHaveClaim(
        'DeleteOwnControlRoomEventReviewEntries'
      ) &&
        this.isOwnEntry())
    ) {
      this.canDelete.set(true);
    }
  }

  isOwnEntry(): boolean {
    if (!this.entry?.createdBy) {
      return false;
    }

    return (
      this.entry.createdBy === this._auth.getUserName() ||
      this._auth.getEmail().startsWith(this.entry.createdBy)
    );
  }

  async deleteEntry(entry: ControlRoomEventReviewEntry): Promise<void> {
    const header = await firstValueFrom(
      this._translateService.get('MONLOG.MESSAGES.HEADERS.DELETE_ENTRY')
    );
    const message = await firstValueFrom(
      this._translateService.get('MONLOG.MESSAGES.CONFIRMATION.DELETE_CRER')
    );
    const ok = await firstValueFrom(
      this._translateService.get('COMMON.LABEL.BUTTONS.OK')
    );
    const cancel = await firstValueFrom(
      this._translateService.get('COMMON.LABEL.BUTTONS.CANCEL')
    );
    this._confirmationService.confirm({
      header: header,
      icon: 'fa fa-question-circle',
      acceptVisible: true,
      rejectVisible: true,
      acceptLabel: ok,
      rejectLabel: cancel,
      message: message,
      accept: () => {
        this.entryWorking.set(true);
        this._monLogApi.deleteCREREntry(entry.id).subscribe({
          next: (result) => {
            this.entryWorking.set(false);
            this.entryDeletedEvent.emit(this.entry);
          },
          error: (error) => {
            console.error(error);
            this.entryWorking.set(false);
          },
        });
      },
      reject: () => {},
    });
  }
}
