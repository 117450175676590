import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { LazyLoadEvent, SelectItem } from 'primeng/api';
import { forkJoin, interval, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { MONLOG_DETAILS_CONTAINER } from 'src/app/constants/common.constants';
import {
  A_AND_E_LOG_CATEGORY,
  A_AND_E_MAINTENANCE,
  M_AND_R_LOG_CATEGORY,
  M_AND_R_MAINTENANCE,
} from 'src/app/constants/monlog-constants';
import { SearchContainerComponent } from 'src/app/core/containers/search-container/search-container.component';
import { TabService } from 'src/app/core/services/tab.service';
import { MonLog } from 'src/app/model/monlogs/mon-log';
import { AuthService } from 'src/app/services/auth.service';
import { DeviceService } from 'src/app/services/device.service';
import { LoadingService } from 'src/app/services/loading.service';
import { LogAndMessageService } from 'src/app/services/log-and-message.service';
import { MonlogsApiService } from 'src/app/services/monlogs-api.service';
import { AuthApiService } from 'src/app/services/auth-api.service';
import { MonlogsBuilderService } from 'src/app/services/monlogs-builder.service';
import { MonLogsTableService } from 'src/app/services/monlogs-table.service';
import { getMonLogColumns } from '../definitions/all-mon-log-table-definition';
import { BreadCrumbBuilderService } from 'src/app/services/breadcrumb-builder.service';
import { BusinessUnitFilterComponent } from 'src/app/core/components/business-unit-filter/business-unit-filter.component';

@Component({
  selector: 'app-monlogs-all-logs-search-container',
  templateUrl: './monlogs-all-logs-search-container.component.html',
  styleUrls: ['./monlogs-all-logs-search-container.component.scss'],
})
export class MonlogsAllLogsSearchContainerComponent
  extends SearchContainerComponent<MonLog>
  implements OnInit, OnDestroy, AfterViewInit
{
  @ViewChild('buFilter') businessUnitFilter: BusinessUnitFilterComponent;
  allLogs: any[] = [];
  activeLogTypes: any[] = [];
  loading: boolean;
  logsLoading: boolean;
  availableCompanies: any[];
  logsLoadingEvent = new EventEmitter<any>();
  lastToken: string = null;
  canCreate = false;
  canEdit = false;
  isOneCall = false;
  additionalFilters = [];
  actions: any[] = [];
  selectedAction: any = 'select';
  statusOptions: SelectItem[];
  mobileFilterCollapsed = true;
  exportFilename: string;
  selectMessage: string = '';
  private _isRefreshing = false;
  refreshSubscription: Subscription;

  constructor(
    private _translateService: TranslateService,
    protected _deviceService: DeviceService,
    private _breadCrumbBuilderService: BreadCrumbBuilderService,
    private _logger: LogAndMessageService,
    private _router: Router,
    private _monLogsApi: MonlogsApiService,
    private _monLogBuilder: MonlogsBuilderService,
    private _authApi: AuthApiService,
    protected _cdRef: ChangeDetectorRef,
    protected _loader: LoadingService,
    protected _tableService: MonLogsTableService,
    protected _auth: AuthService
  ) {
    super(_deviceService, _loader, _auth, _cdRef);
  }

  ngOnInit() {
    this.setTableService(this._tableService);
    if (this._loader.isLoaded()) {
      this.initialize();
    } else {
      this._loader.loadingFinishedEvent.pipe(take(1)).subscribe(() => {
        this.initialize();
      });
    }

    const refreshInterval = interval(60000);
    this.refreshSubscription = refreshInterval.subscribe(() => {
      this.refresh(false);
    });
  }

  ngAfterViewInit(): void {
    this.applyPreviousFilters();
    this.applyPreviousData();
  }

  initialize() {
    this.statusOptions = this._tableService.getStatusOptions();
    this.columns = getMonLogColumns(
      this.getDeviceSize(),
      this.statusOptions,
      this.isMobileDevice()
    );

    const breadCrumb$ = this._translateService.get('MONLOG.SCREEN.ALL_LOGS');
    const header$ = this._translateService.get('MONLOG.SCREEN.ALL_LOGS');
    const exportFilename$ = this._translateService.get(
      'MONLOG.EXPORT_FILE_NAME.ALL_LOGS'
    );
    forkJoin([header$, exportFilename$, breadCrumb$])
      .pipe(take(1))
      .subscribe((mes) => {
        if (this._deviceService.isMobile()) {
          this._breadCrumbBuilderService.resetAndAddBreadCrumb({
            label: mes[2],
            routerLink: null,
          });
        } else {
          TabService.getInstance().updateActiveTabLabel(mes[0]);
        }
        this.screenName = mes[0];
        this.exportFilename = mes[1];
      });

    this._monLogsApi
      .loadAvailableLogTypes()
      .pipe(take(1))
      .subscribe((data) => {
        const clone = { ...data };
        this._tableService.setAvailableTypes(clone.data.getMonLogTypes.items);
      });

    this.canCreate = this._authApi.doesUserHaveAllClaimsFromList([
      'CreateMonLogs',
    ]);
    this.canEdit = this._authApi.doesUserHaveAllClaimsFromList(['EditMonLogs']);
    this.isOneCall = this._authApi.doesUserHaveAllClaimsFromList([
      'CreateEmergencyLocateLogs',
    ]);
    this.updateActionsList();
  }

  updateActionsList() {
    const selectAction$ = this._translateService.get(
      'MONLOG.LABEL.SELECT_ACTION'
    );
    const newCall$ = this._translateService.get(
      'MONLOG.LABEL.BUTTONS.NEW_CALL'
    );
    const newMRCall$ = this._translateService.get(
      'MONLOG.LABEL.BUTTONS.NEW_MR_CALL'
    );
    const newAECall$ = this._translateService.get(
      'MONLOG.LABEL.BUTTONS.NEW_AE_CALL'
    );
    const newControllerCall$ = this._translateService.get(
      'MONLOG.LABEL.BUTTONS.NEW_CONTROLLER_CALL'
    );
    const newOneCall$ = this._translateService.get(
      'MONLOG.LABEL.BUTTONS.NEW_ONE_CALL'
    );

    let select,
      newControllerCall,
      newOneCall,
      newCall,
      newMRCall,
      newAECall = null;
    forkJoin([
      selectAction$,
      newControllerCall$,
      newOneCall$,
      newCall$,
      newMRCall$,
      newAECall$,
    ])
      .pipe(take(1))
      .subscribe((messages) => {
        select = messages[0];
        newControllerCall = messages[1];
        newOneCall = messages[2];
        newCall = messages[3];
        newMRCall = messages[4];
        newAECall = messages[5];
        this.selectMessage = select;

        if (this.canCreate) {
          this.actions = [
            { label: select, value: 'select' },
            { label: newControllerCall, value: 'new-controller-call' },
            { label: newOneCall, value: 'new-one-call' },
            { label: newCall, value: 'new-call' },
            { label: newMRCall, value: 'new-mr-call' },
            { label: newAECall, value: 'new-ae-call' },
          ];
        } else if (this.isOneCall) {
          this.actions = [
            { label: select, value: 'select' },
            { label: newOneCall, value: 'new-one-call' },
          ];
        }

        // default actions here: Send to Day Crew, etc.
        this.actions = [...this.actions];
      });
  }

  actionEvent($event) {
    if ($event.value) {
      this.selectedAction = { label: this.selectMessage, value: 'select' };
      if ($event.value === 'new-call') {
        this.newCall('N');
      } else if ($event.value === 'new-mr-call') {
        this.newCall('MR');
      } else if ($event.value === 'new-ae-call') {
        this.newCall('AE');
      } else if ($event.value === 'new-controller-call') {
        this.newCall('I');
      } else if ($event.value === 'new-one-call') {
        this.newCall('O');
      }
    }
    this._cdRef.markForCheck();
    this._cdRef.detectChanges();
  }

  ngOnDestroy(): void {
    this.refreshSubscription.unsubscribe();
  }

  newCall(category = 'N') {
    const monLog = this._monLogBuilder.buildNullMonLog(category);
    let typeArray = [];
    if (category === 'O') {
      typeArray = [
        ...this._tableService
          .getAvailableTypes()
          .filter((t) => t.name.toLowerCase() === 'emergency locate')
          .map((t) => ({ label: t.name, value: t.id })),
      ];
    } else if (category === M_AND_R_LOG_CATEGORY) {
      typeArray = [
        ...this._tableService
          .getAvailableTypes()
          .filter(
            (t) => t.name.toLowerCase() === M_AND_R_MAINTENANCE.toLowerCase()
          )
          .map((t) => ({ label: t.name, value: t.id })),
      ];
    } else if (category === A_AND_E_LOG_CATEGORY) {
      typeArray = [
        ...this._tableService
          .getAvailableTypes()
          .filter(
            (t) => t.name.toLowerCase() === A_AND_E_MAINTENANCE.toLowerCase()
          )
          .map((t) => ({ label: t.name, value: t.id })),
      ];
    }

    monLog.MonLogTypes = typeArray;
    this._tableService.setSelected(monLog);
    const tab = TabService.getInstance().buildNewTab(
      MONLOG_DETAILS_CONTAINER,
      true
    );
    if (this._deviceService.isMobile()) {
      TabService.getInstance().setMobileTab(tab);
    } else {
      TabService.getInstance().openTab(tab, true);
    }
  }

  /**
   * Method called when the user hits the clear button.
   */
  clearScreen() {
    this.lazyLoad({ lazy: {} });
    this.grid.resetTable();
    this._tableService.clearResults();
    this.elements = [];
  }

  /**
   * Method called when a row double click event is fired.  This navigates to the details page.
   * @param $event - Event from the double click event.
   */
  changeViewEvent($event) {
    this._tableService.setSelected($event.data);
    this._tableService.setLastScreen('all');

    const tab = TabService.getInstance().buildNewTab(
      MONLOG_DETAILS_CONTAINER,
      true,
      null,
      $event.data.id
    );
    if (this._deviceService.isMobile()) {
      TabService.getInstance().setMobileTab(tab);
    } else {
      TabService.getInstance().openTab(tab);
    }
  }

  handleLazyLoad(req, $event, filters) {
    let selectedBusinessUnits = [];
    if (this.businessUnitFilter?.selectedBusinessUnits) {
      // Handle further refreshes & changes to business unit filter component
      selectedBusinessUnits = this.businessUnitFilter.selectedBusinessUnits;
    } else {
      // Handle initial page load (before the business unit filter subcomponent exists)
      selectedBusinessUnits = this._authApi
        .getUserBusinessUnits()
        .map((bu) => bu.id);
    }
    filters['businessUnits'] = selectedBusinessUnits;

    const query = this._monLogBuilder.buildMonLogQuery(filters);
    this.queryNetwork(req, $event, query);
  }

  private queryNetwork(req, $event, query) {
    req.page += 1;
    const sort = {};
    if ($event.lazy && $event.lazy.sortField) {
      // sorting
      if ($event.lazy.sortField === 'statusName') {
        sort['logStatus'] =
          $event.lazy.sortOrder && $event.lazy.sortOrder === 1 ? 'ASC' : 'DESC';
      } else {
        sort[$event.lazy.sortField] =
          $event.lazy.sortOrder && $event.lazy.sortOrder === 1 ? 'ASC' : 'DESC';
      }

      this._tableService.setLastSortField($event.lazy.sortField);
      this._tableService.setLastSortDirection($event.lazy.sortOrder);
    } else {
      sort['createdAt'] = 'DESC';
      this._tableService.setLastSortField('createdAt');
      this._tableService.setLastSortDirection(0);
    }

    if (!this._isRefreshing) {
      this._isRefreshing = true;
      this._monLogsApi
        .queryForMonitoringLogs(req.pageSize, req.page, query, sort)
        .pipe(take(1))
        .subscribe(
          ({ data }) => {
            const clone = Object.assign({}, data);
            this.totalRecords = clone.getMonLogs.totalRecords;
            this.allLogs = [
              ...clone.getMonLogs.items.map((i) =>
                this._monLogBuilder.buildMonLogSearch(i)
              ),
            ];
            this.elements = [...this.allLogs];
            this.loading = false;
            this._cdRef.markForCheck();
            this._isRefreshing = false;
          },
          (error) => {
            console.log(error);
            this.loading = false;
            this._isRefreshing = false;
          }
        );
    }
  }

  handleBusinessUnitChange($event) {
    this.lazyLoad({ lazy: this._tableService.getLastLazyLoad() });
  }

  mobileFilter($event) {
    const newLazy: LazyLoadEvent = {
      filters: {
        ...this.grid.table.filters,
        logId: $event.logId
          ? {
              value: $event.logId,
              matchMode: 'contains',
            }
          : null,
        facilityName: $event.facilityName
          ? {
              value: $event.facilityName,
              matchMode: 'contains',
            }
          : null,
        logStatus: $event.statusName
          ? {
              value: $event.statusName,
              matchMode: 'contains',
            }
          : null,
      },
    };
    this.mobileFilterCollapsed = true;
    this._tableService.setLastLazyLoad(newLazy);
    this.lazyLoad({ lazy: this._tableService.getLastLazyLoad() });
  }

  refresh(setLoading = true): void {
    this.lazyLoad({ lazy: this._tableService.getLastLazyLoad() }, setLoading);
  }
}
